import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../UI'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import { useMutateProgram } from '../../../hooks/Programs/ScholarshipsApplicantOfficer'
import { CreateNewFile, GetFormData, GetNotification } from '../../../Utils'

const ScholarshipsApplicantOfficer = ({ partner, program = null }) => {
  const toast = useToast()
  const refStepOne = useRef(null)
  const refStepTwo = useRef(null)
  const refStepThree = useRef(null)
  const refStepFour = useRef(null)
  const refStepSix = useRef(null)
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })

  const history = useHistory()
  const [stepOneForm, setStepOneForm] = useState({
    partnerPhone: partner?.phone,
    partnerRut: partner?.rut,
    partnerGrade: partner?.grade,
    partnerCode: partner?.code,
    partnerZone: partner?.zone,
    partnerPrefecture: partner?.prefecture,
    partnerEndowment: partner?.endowment
  })
  const [stepTwoForm, setStepTwoForm] = useState({
    postulantName: partner?.name,
    phonePostulant: partner?.phone,
    addressPostulant: partner?.zone,
    unitPostulant: partner?.endowment,
    emailPostulant: partner?.email
  })
  const [stepThreeForm, setStepThreeForm] = useState([])
  const [descriptionsStepThree, setDescriptionsStepThree] = useState({})
  const [stepFourForm, setStepFourForm] = useState({})
  const [stepSixForm, setStepSixForm] = useState({})
  const [finalForm, setFinalForm] = useState({
    partnerId: partner?._id
  })
  const [state, setState] = useState(null)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          setStepTwoForm={setStepTwoForm}
          refStepOne={refStepOne}
          next={nextStep}
        />
      )
    },
    {
      label: 2,
      content: (
        <StepTwo
          stepTwoForm={stepTwoForm}
          setStepTwoForm={setStepTwoForm}
          refStepTwo={refStepTwo}
          next={nextStep}
        />
      )
    },
    {
      label: 3,
      content: (
        <StepThree
          stepThreeForm={stepThreeForm}
          setStepThreeForm={setStepThreeForm}
          descriptionsStepThree={descriptionsStepThree}
          setDescriptionsStepThree={setDescriptionsStepThree}
          refStepThree={refStepThree}
          next={nextStep}
        />
      )
    },
    {
      label: 4,
      content: (
        <StepFour
          stepFourForm={stepFourForm}
          setStepFourForm={setStepFourForm}
          refStepFour={refStepFour}
          next={nextStep}
        />
      )
    },
    {
      label: 5,
      content: (
        <StepFive
          stepSixForm={stepSixForm}
          setStepSixForm={setStepSixForm}
          refStepSix={refStepSix}
          state={state}
        />
      )
    }
  ]

  const handleNext = () => {
    if (activeStep === 0) {
      refStepOne.current()
    }
    if (activeStep === 1) {
      refStepTwo.current()
    }
    if (activeStep === 2) {
      refStepThree.current()
    }
    if (activeStep === 3) {
      refStepFour.current()
    }
    if (activeStep === 4) {
      refStepSix.current()
    }
  }

  useEffect(() => {
    const stepThreeData = []
    const arrayDocumentsStepThree = []
    const beneficiariesHelped = []

    if (stepThreeForm?.length > 0) {
      stepThreeForm.forEach((item) => {
        const { medicalHistory, _id } = item
        if (medicalHistory) {
          if (Object.keys(medicalHistory).length > 0) {
            Object.values(medicalHistory).forEach((doc) => {
              const result = CreateNewFile(doc, _id)
              arrayDocumentsStepThree.push(result)
            })
          }
        }
      })

      stepThreeForm.forEach((item) => {
        // eslint-disable-next-line
        const { medicalHistory, ...rest } = item
        stepThreeData.push(rest)
      })
    }

    beneficiariesHelped.push('Hijo(a)')

    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      ...stepTwoForm,
      ...descriptionsStepThree,
      stepThreeData,
      arrayDocumentsStepThree,
      ...stepFourForm,
      ...stepSixForm,
      beneficiariesHelped
    })
  }, [stepSixForm])

  useEffect(() => {
    // si tiene mas valores aparte de los ID de partner
    // y los el paso activo es el 5
    const test = true
    console.log(finalForm)

    if (test) {
      if (Object.keys(finalForm).length > 1 && activeStep === 4) {
        if (!program) {
          const formData = GetFormData(
            finalForm,
            [
              'academicDocuments',
              'additionalDocuments',
              'arrayDocumentsStepThree',
              'registerSocial',
              'salarySettlements'
            ],
            ['stepThreeData', 'beneficiariesHelped', 'academicDocumentsNames']
          )

          mutate(formData, {
            onSuccess: () => {
              reset()
              GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Registrar programa',
                'error'
              )
            }
          })
        }

        if (program) {
          const { _id: programId } = program
          const formData = GetFormData(
            finalForm,
            [
              'academicDocuments',
              'additionalDocuments',
              'arrayDocumentsStepThree',
              'registerSocial',
              'salarySettlements'
            ],
            [
              'stepThreeData',
              'beneficiariesHelped',
              'academicDocumentsNames',
              'additionalDocumentsNames',
              'registerSocialName',
              'salarySettlementsNames'
            ]
          )
          mutateUpdate(
            { programId, formData },
            {
              onSuccess: () => {
                resetUpdate()
                GetNotification.basic(
                  'Programa actualizado con Éxito',
                  'Aceptar'
                )
                history.push('/programs')
              },
              onError: (err) => {
                console.log(err)
                GetNotification.basic(
                  'Error',
                  'Aceptar',
                  'Error al actualizar programa',
                  'error'
                )
              }
            }
          )
        }
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        receptionDate: program?.receptionDate,
        isPartnerZone: program?.isPartnerZone,
        zone: program?.zone,
        prefecture: program?.prefecture,
        unit: program?.unit,
        civilStatus: program?.civilStatus,
        region: program?.region,
        commune: program?.commune,
        address: program?.address,
        // relationship: program?.relationship,
        partnerEmail: program?.partnerEmail,
        alternativePhone: program?.alternativePhone,
        residenceCondition: program?.residenceCondition,
        observations: program?.observations,
        /* partner field */
        partnerPhone: program?.partner.phone,
        partnerRut: program?.partner.rut,
        partnerGrade: program?.partner.grade,
        partnerCode: program?.partner.code,
        partnerZone: program?.partner.zone,
        partnerPrefecture: program?.partner.prefecture,
        partnerEndowment: program?.partner.endowment
      })
      setStepTwoForm({
        postulantName: program?.postulantName,
        phonePostulant: program?.phonePostulant,
        addressPostulant: program?.addressPostulant,
        unitPostulant: program?.unitPostulant,
        emailPostulant: program?.emailPostulant,
        fuasRequest: program?.fuasRequest,
        score: program?.score,
        academicDocumentsNames: program?.academicDocuments
        // antiquity: program?.antiquity,
        // commandersName: program?.commandersName,
        // grade: program?.grade,
        // academicOpinion: program?.academicOpinion,
        // monthlyFeeStatement: program?.monthlyFeeStatement
      })

      setStepThreeForm(
        program?.familyGroupBackground.map((item) => ({
          ...item,
          medicalHistoryName: item.medicalHistory || [],
          medicalHistory: null
        }))
      )
      setDescriptionsStepThree({
        descriptionsFamilyMembers: program?.descriptionsFamilyMembers
      })

      setStepFourForm({
        salatyPartner: program?.salatyPartner,
        salarySpouse: program?.salarySpouse,
        otherSalaty: program?.otherSalaty,
        salarySettlementsNames: program?.salarySettlements
      })

      setStepSixForm({
        registerSocialName: program?.registerSocial,
        additionalDocumentsNames: program?.additionalDocuments,
        observationsRequest: program?.observationsRequest
      })
    }
  }, [program])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 1 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
            isLoading={isLoading || isLoadingUpdate}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          onClick={() => {
            setState('INREVIEW')
            handleNext()
          }}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
        />
      </Flex>
    </Flex>
  )
}

export default ScholarshipsApplicantOfficer
