import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import {
  Box,
  Checkbox,
  Flex,
  useDisclosure,
  useToast,
  Button,
  HStack,
  Stack,
  Text,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge
} from '@chakra-ui/react'
import {
  BarFileName,
  InputComtrol,
  TextAreaComtrol,
  UploadFile,
  CustomToast,
  SectionAccordion
} from '../../../UI'
import ModalHistory from '../../ModalHistory'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'
import {
  GetNotification,
  GetFormData,
  GetKey,
  CondicionalModal,
  Files,
  Schedule,
  FormatMoney,
  FormatDate,
  ClearFormatMoney
} from '../../../../Utils'
import { useTypeModal } from '../../../../hooks/TypeModal'
import { useMutateProgram } from '../../../../hooks/Programs/WorkshopsAndTraining'
import { useAdditionalInformation } from '../../../../hooks/AdditionalInformation'
import { openResignationModal } from '../../../../store/slices/modal'
import RenderDocument from '../../ActionStepEdit/RenderDocument'

import { schema } from './Schema'
import VoucherIntructive from './VoucherIntructive'
import DocumentsUniversityOrder from './DocumentsUniversityOrder'
import DocumentsSolidarityOrder from './DocumentsSolidarityOrder'
import RenderGeneralDocument from './RenderGeneralDocument'
import RequestPaymentMonitors from './RequestPaymentMonitors'
import RenderBottonsOptions from './RenderBottonsOptions'
import RenderInputRequired from './RenderInputRequired'
import RenderVouchersRequired from './RenderVouchersRequired'
import RenderDebtCertificateRequired from './RenderDebtCertificateRequired'
import SwitchSkipStep from './SwitchSkipStep'
import RenderArrayDescriptions from './RenderArrayDescriptions'
import RenderPreviewPastStep from './RenderPreviewPastStep'
import RenderCheckProcessRequired from './RenderCheckProcessRequired'
import RenderCheckRequired from './RenderCheckRequired'
import RenderSelectServiceRequired from './RenderSelectServiceRequired'
import RenderCommission from './RenderCommission'
import RenderValidationStep from './RenderValidationStep'
import TableDiscountsPartners from '../../../TableDiscountsPartners'
import ParticipantsList from '../../../DataViewPrograms/WorkshopsAndTraining/ParticipantsList'

const OtherProcesses = ({
  currentStep,
  getColor,
  program,
  requestSelected,
  otherRequestSelected
}) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const {
    requiredPurchaseOrderVerification,
    requiredDescriptions,
    requiredDocuments,
    requiredDate,
    requiredGiftActivate,
    requiredCheckProcess,
    requiredCommission,
    requiredPayment,
    requiredVadiationOffice,
    requiredAuthorization,
    requiredProposedState,
    requiredAmount,
    requiredOfficeStatus,
    requiredVoucher,
    requiredInstructive,
    requiredEntryDate,
    requiredBackupFeeAmount,
    requiredScholarshipCommission,
    requiredBankData,
    requiredOffice,
    requiredInvoice,
    requiredTariffDifferenceDocuments,
    requiredTransferredAmount,
    requiredUsedAmount,
    requiredPaymentMonitors,
    requiredVerificationOfDocuments,
    requiredMoreDescriptions,
    requiredReportedEmail,
    requiredChangeState,
    canImcompleteStep,
    canDesistProgram,
    requiredBoxPurchase,
    requiredBoxPurchaseTwo,
    requiredPurchaseRequestLetter,
    requiredPurchaseRequestLetterSigned,
    requiredQuotesReceived,
    requiredDebtCertificate,
    requiredDateSubmissionReceipts,
    requiredDateSubmissionReceiptsArr,
    requiredCheckVouchersSend,
    requiredDateCertificateRequest,
    requiredDateCertificateReception,
    requiredDocumentsSentMedicalInstitution,
    requiredDateSend,
    requiredQuestion
  } = CondicionalModal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    title,
    actionKey,
    succesMessage,
    errorMessage,
    titleDocument,
    titleObservation,
    buttonsProps,
    buttonsPropsExtra,
    keyToSearch,
    validation
  } = useTypeModal(currentStep?.key)
  const { data, isPending } = useAdditionalInformation(
    program?._id,
    currentStep?.key,
    currentStep?.step,
    requestSelected,
    otherRequestSelected
  )

  const { mutate, isLoading, reset } = useMutateHistoryProgram(actionKey)
  const { mutate: mutateDownloadFile, isLoading: isLoadingFile } =
    useMutateProgram('DOWNLOAD-REGISTERED')

  const getTypeProcess = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (additionalInformation.length > 0) {
      return {
        medicines: additionalInformation[0]?.medicines,
        supplies: additionalInformation[0]?.supplies,
        tariffDifference: additionalInformation[0]?.tariffDifference,
        milkFormula: additionalInformation[0]?.milkFormula,
        others: additionalInformation[0]?.others,
        service: additionalInformation[0]?.service,
        otherService: additionalInformation[0]?.otherService,
        commission: additionalInformation[0]?.commission
      }
    }

    return {
      medicines: false,
      supplies: false,
      tariffDifference: false,
      milkFormula: false,
      others: false,
      service: null,
      otherService: null,
      commission: null
    }
  }

  const typeProcess = getTypeProcess()

  // console.log('Other process otherRequestSelected: ', otherRequestSelected)

  const initialValues = {
    /* data General */
    stepProgram: currentStep?.step,
    isRequiredDescriptions: false,
    descriptions: '',
    isRequiredMoreDescriptions: false,
    moreDescriptions: [],
    isRequiredDocuments: false,
    documentsNames: [],
    documents: null,
    program: program?._id,
    currentKeyHistory: currentStep.key,
    keyHistory:
      GetKey.getNextStep(
        currentStep?.key,
        program?.type,
        program?.history,
        getTypeProcess()
      ) || GetKey.PostChangeStatus[program.type] /* es el siguiente paso */,
    state: program?.state,
    /* end data General */

    /* data NewbornBenefit */
    isRequiredDate: false,
    dateDelivery: '',
    isRequiredGiftCardActive: false,
    giftcardActive: false,
    /* end data NewbornBenefit */

    /* data university, pre-university, Technical-professional, Support In Case Of Death Of Member */
    isRequiredPayment: false,
    paymentVerification: '',
    /* end data university, pre-university, Technical-professional, Support In Case Of Death Of Member */

    /* data university, pre-universitary, Technical-professional, applicant-Officer */
    isRequiredAuthorization: false,
    orderAuthorization: '',
    isRequiredAmount: false,
    amount: '',
    isReuiredAlterProcess: false,
    alterProcess: '',
    isRequiredQuestionNewSemesters: false,
    responseQuestionNewSemesters: '',
    completedSemesters: program?.completedSemesters || 0,
    /* end data university, pre-universitary, Technical-professional, applicant-Officer */

    /* data Disaster-Support */
    isRequiredProposedState: false,
    proposedState: '',
    isRequiredOfficeStatus: false,
    officeStatus: '',
    /* end data Disaster-Support */

    /* data DisasterSupport */
    isRequiredVoucher: false,
    voucherName: [],
    voucher: null,
    isRequiredInstructive: false,
    instructiveName: [],
    instructive: null,
    /* end data DisasterSupport */

    /* data university Scholarships */
    isRequiredBackupFeeAmount: false,
    backupFeeAmountName: [],
    backupFeeAmount: null,
    isRequiredScholarshipCommission: false,
    scholarshipCommissionName: [],
    scholarshipCommission: null,
    isRequiredBankData: false,
    bankDataName: [],
    bankData: null,
    isRequiredPurchaseOrderVerification: false,
    purchaseOrderVerification: '',
    /* end data university Scholarships */

    /* data fss, incendio, catastrofe */
    isRequiredUsedAmount: false,
    usedAmount: '',
    /* end data fss, incendio, catastrofe */
    /* data university, pre-university, Technical Professional Scholarships */
    isRequiredTransferredAmount: false,
    transferredAmount: '',
    newbornTransferedValues: {},
    monitorTransferedValues: {},
    isRequiredOffice: false,
    officeName: [],
    office: null,
    isRequiredInvoice: false,
    invoiceName: [],
    invoice: null,
    isRequiredValidationOffice: false,
    validationOffice: '',
    isRequiredReportedEmail: false,
    reportedEmail: '',
    /* end data university, pre-university, Technical Professional Scholarships */

    /* data Monitor -WorkshopsAndTraining */
    isRequiredPaymentMonitors: false,
    classScheduleNames: [],
    classSchedule: null,
    feeBillNames: [],
    feeBill: null,
    attendanceSheetNames: [],
    attendanceSheet: null,
    isRequiredVerificationOfDocuments: false,
    verificationOfDocuments: '',
    /* end data Monitor -WorkshopsAndTraining */

    /* data hospital accommodation */
    isRequiredEntryDate: false,
    entryDate: '',
    entryHour: Schedule.hours.at(0),
    entryMinute: Schedule.minutes.at(0),
    entryPeriod: Schedule.periods.at(0),
    /* end hospital accommodation */

    /* data Solidarity Health Fund */
    isRequiredCommission: false,
    commission: '',
    isRequiredCheckProcessActive: false,
    medicines: false,
    supplies: false,
    tariffDifference: false,
    milkFormula: false,
    others: false,
    assistanceBenefits: false,
    someSelectedProcess: Boolean(
      currentStep?.key !== 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND' ||
        currentStep?.key !== 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND'
    ),
    isRequiredService: false,
    service: '',
    isRequiredOtherService: false,
    otherService: '',

    isRequiredTariffDifferenceDocuments: false,
    tariffDifferenceDocumentsNames: [],
    tariffDifferenceDocuments: null,

    isRequiredBoxPurchase: false,
    boxPurchaseName: [],
    boxPurchase: null,
    isRequiredPurchaseRequestLetter: false,
    purchaseRequestLetterName: [],
    purchaseRequestLetter: null,
    isRequiredQuotesReceived: false,
    quotesReceivedName: [],
    quotesReceived: null,
    isRequiredPurchaseRequestLetterSigned: false,
    purchaseRequestLetterSignedName: [],
    purchaseRequestLetterSigned: null,

    isRequiredCheckVouchersSend: false,
    vouchersSend: false,
    invoiceRequest: false,
    isRequiredDateSubmissionReceipts: false,
    dateSubmissionReceipts: '',
    isRequiredDateSubmissionReceiptsArr: false,
    dateSubmissionReceiptsArr: [],

    isRequiredDateCertificateRequest: false,
    dateCertificateRequest: '',
    isRequiredDateCertificateReception: false,
    dateCertificateReception: '',
    isRequiredDocumentsSentMedicalInstitution: false,
    documentsSentMedicalInstitutionName: [],
    documentsSentMedicalInstitution: null,
    oficioSheetNames: [],
    oficioSheet: null,

    isRequiredDateSend: false,
    dateSend: '',

    isRequiredDebtCertificate: false,
    debtCertificateName: [],
    debtCertificate: null,

    isRequiredBoxPurchaseTwo: false,
    boxPurchaseTwoName: [],
    boxPurchaseTwo: null,
    /* end data Solidarity Health Fund */

    canChangeState: false,
    canImcompleteStep: false,
    canDesistProgram: false,
    incompleteStep: false,
    desistProgram: false,
    blockedStep: false
  }
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (values) => {
      const clearAmountValue = Boolean(
        requiredTransferredAmount?.includes(currentStep.key)
      )

      if (clearAmountValue) {
        values.amount = ''
      }

      if (otherRequestSelected) {
        values.subDocumentSelected = otherRequestSelected
      }

      if (requestSelected) {
        values.documentSelected = requestSelected
      }

      if (values.dateSubmissionReceiptsArr.length > 0) {
        values.dateSubmissionReceiptsArr =
          values.dateSubmissionReceiptsArr?.map((item) => item.date)
      }

      const formData = GetFormData(
        values,
        [
          'documents',
          'voucher',
          'instructive',
          'backupFeeAmount',
          'scholarshipCommission',
          'bankData',
          'boxPurchase',
          'boxPurchaseTwo',
          'purchaseRequestLetter',
          'quotesReceived',
          'debtCertificate',
          'purchaseRequestLetterSigned',
          'documentsSentMedicalInstitution',
          'office',
          'invoice',
          'tariffDifferenceDocuments',
          'classSchedule',
          'feeBill',
          'attendanceSheet',
          'oficioSheet'
        ],
        [
          'moreDescriptions',
          'dateSubmissionReceiptsArr',
          'newbornTransferedValues',
          'monitorTransferedValues'
        ]
      )
      const send = true

      if (!send) {
        console.log(values)
      }

      if (send) {
        mutate(formData, {
          onSuccess: () => {
            reset()
            formik.resetForm()
            onClose()
            GetNotification.basic(succesMessage, 'Aceptar')
          },
          onError: (err) => {
            const response = err?.response?.data

            GetNotification.basic(
              'Error',
              'Aceptar',
              response?.type === 'validateLastStep'
                ? response.message
                : errorMessage,
              'error'
            )
          }
        })
      }
    }
  })

  useEffect(() => {
    if (
      formik.values.others &&
      currentStep?.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    ) {
      formik.setValues({
        ...formik.values,
        medicines: false,
        supplies: false,
        tariffDifference: false,
        milkFormula: false,
        service: '',
        otherService: '',
        assistanceBenefits: false
      })
    }
  }, [
    formik.values.others,
    formik.values.medicines,
    formik.values.supplies,
    formik.values.tariffDifference,
    formik.values.milkFormula,
    formik.values.assistanceBenefits
  ])

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  const getTransferredAmountForNewbornBenefit = () => {
    if (currentStep?.key === 'GIFTCARD-RECEPTION') {
      const nroOfNewborns = program?.newbornList?.length
      return FormatMoney((40000 * nroOfNewborns).toString())
    }
    return ''
  }

  const getTitle = () => {
    if (
      currentStep?.key ===
        'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING' &&
      program?.isFreeProgram
    ) {
      return 'Información finalización'
    }
    return title
  }

  const generateTemplateFile = () => {
    toast({
      duration: 9000 * 9000,
      isClosable: true,
      position: 'bottom-right',
      render: ({ onClose: handleClose }) => (
        <CustomToast
          text={'Espere por favor...'}
          status={'info'}
          onClose={handleClose}
        />
      )
    })
    mutateDownloadFile(
      { registeredPartners: program?.registeredPartners || [] },
      {
        onSuccess: () => {
          toast.closeAll()
          reset()
          toast({
            duration: 5000,
            isClosable: true,
            position: 'bottom-right',
            render: ({ onClose: handleClose }) => (
              <CustomToast
                text={'Descarga exitosa'}
                status={'success'}
                showSpinner={false}
                onClose={handleClose}
              />
            )
          })
        },
        onError: (err) => {
          toast.closeAll()
          reset()
          toast({
            duration: 5000,
            isClosable: true,
            position: 'bottom-right',
            render: ({ onClose: handleClose }) => (
              <CustomToast
                text={'Error al descargar reporte'}
                status={'error'}
                showSpinner={false}
                onClose={handleClose}
              />
            )
          })
          console.log(err)
        }
      }
    )
  }

  useEffect(() => {
    if (
      currentStep?.key !==
        'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' &&
      currentStep?.key !== 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
    ) {
      formik.setFieldValue(
        'isRequiredDocuments',
        Boolean(formik.values.purchaseOrderVerification === 'APPROVED')
      )
    }
  }, [formik.values.purchaseOrderVerification, currentStep])

  useEffect(() => {
    if (
      currentStep?.key !==
        'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' &&
      currentStep?.key !== 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
    ) {
      formik.setFieldValue(
        'isRequiredDocuments',
        formik.values.paymentVerification === 'APPROVED'
      )
    }
  }, [formik.values.paymentVerification, currentStep])

  useEffect(() => {
    if (isOpen) {
      formik.resetForm(initialValues)

      /* --- */
      formik.setValues({
        ...initialValues,
        isRequiredDate: requiredDate.includes(currentStep?.key),
        isRequiredGiftCardActive: requiredGiftActivate.includes(
          currentStep?.key
        ),
        isRequiredCheckProcessActive:
          (requiredCheckProcess.includes(currentStep?.key) &&
            currentStep?.key ===
              'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND') ||
          (requiredCheckProcess.includes(currentStep?.key) &&
            typeProcess.others),
        isRequiredCommission: requiredCommission.includes(currentStep?.key),
        isRequiredCheckVouchersSend: requiredCheckVouchersSend.includes(
          currentStep?.key
        ),
        isRequiredDescriptions: requiredDescriptions.includes(currentStep?.key),
        isRequiredDocuments: requiredDocuments.includes(currentStep?.key),
        isRequiredPayment: requiredPayment.includes(currentStep?.key),
        isRequiredAuthorization: requiredAuthorization.includes(
          currentStep?.key
        ),
        isRequiredProposedState: requiredProposedState.includes(
          currentStep?.key
        ),
        isRequiredAmount: requiredAmount.includes(currentStep?.key),
        isRequiredQuestionNewSemesters: requiredQuestion.includes(
          currentStep?.key
        ),
        isRequiredOfficeStatus: requiredOfficeStatus.includes(currentStep?.key),
        isRequiredVoucher: requiredVoucher.includes(currentStep?.key),
        isRequiredInstructive: requiredInstructive.includes(currentStep?.key),
        isRequiredBackupFeeAmount: requiredBackupFeeAmount.includes(
          currentStep?.key
        ),
        isRequiredScholarshipCommission: requiredScholarshipCommission.includes(
          currentStep?.key
        ),
        isRequiredBankData: requiredBankData.includes(currentStep?.key),
        isRequiredOffice: requiredOffice.includes(currentStep?.key),
        isRequiredInvoice: requiredInvoice.includes(currentStep?.key),
        isRequiredTariffDifferenceDocuments:
          requiredTariffDifferenceDocuments.includes(currentStep?.key) &&
          (typeProcess.medicines || typeProcess.supplies) &&
          typeProcess.tariffDifference &&
          typeProcess.service === 'Dipreca',
        isRequiredTransferredAmount: requiredTransferredAmount.includes(
          currentStep?.key
        ),
        transferredAmount: getTransferredAmountForNewbornBenefit(),
        isRequiredUsedAmount: requiredUsedAmount.includes(currentStep?.key),
        isRequiredPaymentMonitors: requiredPaymentMonitors.includes(
          currentStep?.key
        ),
        isRequiredVerificationOfDocuments:
          requiredVerificationOfDocuments.includes(currentStep?.key),
        canChangeState: requiredChangeState.includes(currentStep?.key),
        canImcompleteStep: canImcompleteStep.includes(currentStep?.key),
        canDesistProgram: canDesistProgram[program.type]
          ? canDesistProgram[program.type].includes(currentStep?.key)
          : false,
        isRequiredMoreDescriptions: requiredMoreDescriptions.includes(
          currentStep?.key
        ),
        isRequiredValidationOffice: requiredVadiationOffice.includes(
          currentStep?.key
        ),
        isRequiredReportedEmail: requiredReportedEmail.includes(
          currentStep?.key
        ),
        isRequiredEntryDate: requiredEntryDate.includes(currentStep?.key),
        isRequiredBoxPurchase: requiredBoxPurchase.includes(currentStep?.key),
        isRequiredBoxPurchaseTwo: requiredBoxPurchaseTwo.includes(
          currentStep?.key
        ),
        isRequiredPurchaseRequestLetter: requiredPurchaseRequestLetter.includes(
          currentStep?.key
        ),
        isRequiredQuotesReceived:
          (requiredQuotesReceived.includes(currentStep?.key) &&
            !typeProcess.tariffDifference &&
            typeProcess.service !== 'Dipreca') ||
          (requiredQuotesReceived.includes(currentStep?.key) &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Otras') ||
          (requiredQuotesReceived.includes(currentStep?.key) &&
            (typeProcess.medicines || typeProcess.supplies) &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Dipreca'),
        isRequiredDebtCertificate: requiredDebtCertificate.includes(
          currentStep?.key
        ),
        isRequiredPurchaseRequestLetterSigned:
          requiredPurchaseRequestLetterSigned.includes(currentStep?.key),
        isRequiredDateSubmissionReceipts:
          requiredDateSubmissionReceipts.includes(currentStep?.key),
        isRequiredDateSubmissionReceiptsArr:
          requiredDateSubmissionReceiptsArr.includes(currentStep?.key),
        isRequiredDateCertificateRequest:
          requiredDateCertificateRequest.includes(currentStep?.key),
        isRequiredDateCertificateReception:
          requiredDateCertificateReception.includes(currentStep?.key),
        isRequiredDocumentsSentMedicalInstitution:
          requiredDocumentsSentMedicalInstitution.includes(currentStep?.key),
        isRequiredDateSend: requiredDateSend.includes(currentStep?.key),
        isRequiredPurchaseOrderVerification:
          requiredPurchaseOrderVerification.includes(currentStep?.key),
        isFreeProgram: program?.isFreeProgram
      })
    }
  }, [isOpen])

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      isRequiredDocuments:
        !formik.values.incompleteStep && !formik.values.desistProgram
    })
  }, [formik.values.incompleteStep, formik.values.desistProgram])

  useEffect(() => {
    if (
      formik.values.documentsNames.length > 0 &&
      !formik.values.isRequiredDocuments
    ) {
      formik.setValues({
        ...formik.values,
        documentsNames: [],
        documents: null
      })
    }
  }, [formik.values.isRequiredDocuments])

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  useEffect(() => {
    if (
      isOpen &&
      currentStep?.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    ) {
      const { supplies, tariffDifference, medicines, milkFormula, others } =
        formik.values

      const validateProcess = Boolean(
        supplies || tariffDifference || medicines || milkFormula || others
      )

      formik.setFieldValue('someSelectedProcess', validateProcess)
    }

    if (
      isOpen &&
      currentStep?.key === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' &&
      typeProcess.others
    ) {
      const {
        supplies,
        tariffDifference,
        medicines,
        milkFormula,
        others,
        assistanceBenefits
      } = formik.values

      const validateProcess = Boolean(
        supplies ||
          tariffDifference ||
          medicines ||
          milkFormula ||
          others ||
          assistanceBenefits
      )

      formik.setFieldValue('someSelectedProcess', validateProcess)
    }
  }, [
    formik.values.supplies,
    formik.values.tariffDifference,
    formik.values.medicines,
    formik.values.milkFormula,
    formik.values.others,
    formik.values.assistanceBenefits,
    isOpen
  ])

  const getOCHistory = (ocName) => {
    if (!program?.history.history) {
      return 'Sin avance'
    }

    const ocHistory = program?.history.history
      .filter((item) => item.subDocumentFlow === ocName)
      .sort((a, b) => b.step - a.step)

    return ocHistory[0]?.title || 'Sin avance'
  }

  const getDisabledButton = () => {
    if (
      currentStep?.key !==
      'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND'
    ) {
      return false
    }

    // buscamos las solicitudes de ordenes de compras para luego listar las orden de compras de cada solicitud
    // const purchaseLatterRequest = program?.additionalInformation?.find(
    //   (info) => info.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
    // )?.purchaseRequestLetter

    // let disabled = false

    // console.log('purchaseLatterRequest: ', purchaseLatterRequest)

    // purchaseLatterRequest.forEach((purchaseLatter) => {
    //   // buscamos los pasos que pertenezcan a la solicitud de orden de compra seleccionada
    //   const ocHistory = program?.history.history.filter(
    //     (item) => item.documentFlow === purchaseLatter.newName
    //   )

    //   if (!disabled) {
    //     // validamos que el historial tenga el paso "Orden de compra" y que este completo. Si no lo tiene, se deshabilita el boton de submit
    //     disabled = !ocHistory.some(
    //       (item) =>
    //         item.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
    //         item.complete
    //     )
    //   }
    // })

    // // buscamos las ordenes de compras
    // const purchaseRequestLetter = program?.additionalInformation?.filter(
    //   (info) => info.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
    // )

    // // agrupamos todas las Ordenes de compras de todas las solicitudes
    // const allPurchaseRequestLetter = purchaseRequestLetter
    //   ?.map((item) => item.documents)
    //   .flat(1)

    // console.log('allPurchaseRequestLetter: ', allPurchaseRequestLetter)

    // allPurchaseRequestLetter.forEach((purchaseLatter) => {
    //   const ocHistory = program?.history.history
    //     .filter((item) => item.subDocumentFlow === purchaseLatter.newName)
    //     .sort((a, b) => b.step - a.step)

    //   if (!disabled) {
    //     disabled =
    //       ocHistory[0]?.key !==
    //       'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND'
    //   }
    // })

    // return disabled
    return false
  }

  const handleGetOcMontoEjecutado = () => {
    const transferedAmmounts = program?.additionalInformation?.filter(
      (aditionalInfo) =>
        aditionalInfo.key === 'USED-AMOUNT-SOLIDARITY-HEALTH-FUND'
    )

    const allOcs = program?.additionalInformation
      ?.filter(
        (info) => info.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
      )
      .map((item) => item.documents)
      .flat(1)

    const ocMontoEjecutado = allOcs.map((oc) => {
      const ocHistory = transferedAmmounts?.find(
        (item) => item.subDocumentSelected === oc.newName
      )

      return {
        key: oc.newName,
        name: oc.name,
        montoEjecutado: ocHistory
          ? Number(ClearFormatMoney(ocHistory.transferredAmount))
          : 0
      }
    })

    const totalCount = ocMontoEjecutado.reduce(
      (acc, item) => acc + item.montoEjecutado,
      0
    )

    ocMontoEjecutado.push({
      key: 'Total',
      name: 'Total',
      montoEjecutado: totalCount
    })

    return ocMontoEjecutado.filter((item) => item.montoEjecutado > 0)
  }

  if (isPending) {
    return <>Cargando</>
  }

  return (
    <ModalHistory
      title={getTitle()}
      colors={getColor}
      disable={currentStep?.complete}
      disableButtons={getDisabledButton()}
      isLoading={isLoading}
      onClick={formik.handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
        <Box w={'100%'}>
          <HStack spacing={4}>
            {formik.values.canDesistProgram && (
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose()
                  dispatch(openResignationModal())
                }}
              >
                Desistir
              </Button>
            )}
            {formik.values.canImcompleteStep && (
              <SwitchSkipStep formik={formik} />
            )}
          </HStack>
          <RenderBottonsOptions
            formik={formik}
            buttonsProps={buttonsProps}
            buttonsPropsExtra={buttonsPropsExtra}
            currentStep={currentStep}
            program={program}
          />
          {currentStep?.key ===
            'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION' && (
            <>
              <Grid
                templateColumns={{
                  lg: 'repeat(2, 1fr)',
                  md: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)'
                }}
              >
                <GridItem
                  ml={'auto'}
                  mr={'auto'}
                  border={'2px dotted #82B378'}
                  borderRadius={8}
                  p={4}
                  mb={2}
                >
                  <Flex
                    justify={'center'}
                    align={'center'}
                    direction={'column'}
                  >
                    <Text>Fecha inicial de Entrada:</Text>
                    <Text>{FormatDate(program.incomeDate)}</Text>
                  </Flex>
                </GridItem>
                <GridItem
                  ml={'auto'}
                  mr={'auto'}
                  border={'2px dotted #82B378'}
                  borderRadius={8}
                  p={4}
                  mb={2}
                >
                  <Flex
                    justify={'center'}
                    align={'center'}
                    direction={'column'}
                  >
                    <Text>Fecha Inicial de Salida:</Text>
                    <Text>{FormatDate(program.exitDate)}</Text>
                  </Flex>
                </GridItem>
              </Grid>
              {program.isRequiredExtension && (
                <Grid templateColumns={'repeat(1, 1fr)'}>
                  <GridItem
                    ml={'auto'}
                    mr={'auto'}
                    border={'2px dotted #82B378'}
                    borderRadius={8}
                    p={4}
                    mb={2}
                  >
                    <Flex
                      justify={'center'}
                      align={'center'}
                      direction={'column'}
                    >
                      <Text>Última Fecha Actualizada de Salida:</Text>
                      <Text>{FormatDate(program.extensionDate)}</Text>
                    </Flex>
                  </GridItem>
                </Grid>
              )}
            </>
          )}
          {/* {currentStep?.key ===
            'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND' && (
            <>
              <Flex
                as="h4"
                mb={4}
                fontWeight={'semibold'}
                lineHeight={'tight'}
                align={'center'}
                color={'green.100'}
                isTruncated
              >
                <Text noOfLines={1}>
                  Estado de Solicitudes de Ordenes de compras:
                </Text>
              </Flex>
              {program?.additionalInformation
                ?.find(
                  (info) =>
                    info.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
                )
                ?.purchaseRequestLetter.map((purchaseRequest) => (
                  <Box mb={6}>
                    <Text fontWeight={700} key={purchaseRequest.newName}>
                      Solicitud: {purchaseRequest.name}
                    </Text>
                    <TableContainer>
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Orden de compra</Th>
                            <Th>Paso actual</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {program?.additionalInformation
                            ?.find(
                              (info) =>
                                info.key ===
                                  'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
                                info.documentSelected ===
                                  purchaseRequest.newName
                            )
                            ?.documents.map((document) => (
                              <Tr key={document.newName}>
                                <Td>{document.name}</Td>
                                <Td>{getOCHistory(document.newName)}</Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                ))}
              <Text color="orange" align="center" mb={4}>
                Nota: Solo podra completar este paso si todas las ordenes de
                compras se encuentran aprobadas y en envio y recepción de actas.
              </Text>
            </>
          )} */}
          {keyToSearch && (
            <RenderPreviewPastStep
              program={program}
              keyToSearch={keyToSearch}
              requestSelected={requestSelected}
              otherRequestSelected={otherRequestSelected}
              currentKey={currentStep?.key}
            />
          )}

          {/* {currentStep?.key === 'USED-AMOUNT-SOLIDARITY-HEALTH-FUND' && (
            <>
              <Flex
                as="h4"
                mb={4}
                fontWeight={'semibold'}
                lineHeight={'tight'}
                align={'center'}
                color={'green.100'}
                isTruncated
              >
                <Text noOfLines={1}>Detalle de Ordenes de compras:</Text>
              </Flex>
              <Box mb={6}>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Orden de compra</Th>
                        <Th isNumeric>Monto ejecutado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {handleGetOcMontoEjecutado().map(
                        (purchaseRequest, index) => (
                          <Tr
                            key={purchaseRequest.name}
                            sx={{
                              backgroundColor:
                                handleGetOcMontoEjecutado().length === index + 1
                                  ? 'gray.100'
                                  : 'white'
                            }}
                          >
                            <Td>{purchaseRequest.name}</Td>
                            <Td isNumeric>
                              $ {FormatMoney(purchaseRequest.montoEjecutado)}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )} */}

          {validation && (
            <RenderValidationStep
              program={program}
              formik={formik}
              validation={validation}
            />
          )}

          {/* Mostramos la lista de inscritos en el programa */}
          {['REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING'].includes(
            currentStep?.key
          ) && (
            <SectionAccordion title={'Lista de socios y cónyuges inscritos'}>
              <Flex justifyContent="flex-end">
                <Button
                  marginBottom={4}
                  colorScheme="teal"
                  isLoading={isLoadingFile}
                  onClick={generateTemplateFile}
                >
                  Descargar participantes
                </Button>
              </Flex>

              <Stack maxHeight={300} overflowY="auto">
                <ParticipantsList program={program} />
              </Stack>
            </SectionAccordion>
          )}

          {/* Mostramos la tabla de descuentos depende del paso */}
          {[
            'REVIEW-DOCUMENTS-SOLIDARITY-HEALTH-FUND',
            'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT'
          ].includes(currentStep?.key) && (
            <>
              <Text fontWeight={700}>Descuentos:</Text>
              <TableDiscountsPartners
                filtersDiscounts={{
                  id: program.partner._id,
                  order: 'DESC'
                }}
                limit={12}
                tableSize="sm"
              />
            </>
          )}

          {currentStep?.key ===
            'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING' && (
            <>
              <Text fontSize={16} fontWeight={600} color={'green.100'}>
                Tema: {program.topic}
              </Text>
              <Text fontSize={16} fontWeight={600} color={'green.100'}>
                Zona: {program.zone}
              </Text>
              <Text fontSize={16} fontWeight={600} color={'green.100'}>
                ¿Programa gratuito?: {program.isFreeProgram ? 'Sí' : 'No'}
              </Text>
              <Text fontSize={16} fontWeight={600} color={'green.100'}>
                Fecha de inicio:{' '}
                {new Date(program.startDate).toLocaleDateString()}
              </Text>
              <Text fontSize={16} fontWeight={600} color={'green.100'}>
                Fecha de termino:{' '}
                {new Date(program.endDate).toLocaleDateString()}
              </Text>
            </>
          )}

          <RenderInputRequired formik={formik} program={program} />
          <RenderVouchersRequired formik={formik} />
          <RenderDebtCertificateRequired
            formik={formik}
            typeProcess={typeProcess}
            currentStepKey={currentStep?.key}
          />
          {formik.values.isRequiredPaymentMonitors && (
            <RequestPaymentMonitors formik={formik} program={program} />
          )}
          <DocumentsUniversityOrder formik={formik} />
          <DocumentsSolidarityOrder
            formik={formik}
            program={program}
            typeProcess={typeProcess}
            currentStepKey={currentStep?.key}
          />
          {data?.purchaseRequestLetterSigned?.length > 0 && (
            <RenderDocument
              titleDocument={'Oficio de solicitud de orden de compra firmada'}
              arrayDocuments={data?.purchaseRequestLetterSigned}
            />
          )}

          <RenderGeneralDocument formik={formik} program={program} />
          {(formik.values.isRequiredVoucher ||
            formik.values.isRequiredInstructive) && (
            <VoucherIntructive formik={formik} />
          )}
          {formik.values.isRequiredCommission && (
            <InputComtrol
              isRequired={formik.values.isRequiredCommission}
              id={'commission'}
              label={'Comisión'}
              type={'text'}
              value={formik.values.commission}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.commission)}
              errorText={formik.errors.commission}
            />
          )}
          {currentStep?.key ===
            'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' && (
            <RenderCommission program={program} />
          )}
          <RenderCheckProcessRequired
            formik={formik}
            stepKey={currentStep?.key}
          />
          <RenderSelectServiceRequired formik={formik} />
          {formik.values.service === 'Otras' && (
            <InputComtrol
              isRequired={formik.values.isRequiredOtherService}
              id={'otherService'}
              label={'Otro servicio'}
              type={'text'}
              value={formik.values.otherService}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.otherService)}
              errorText={formik.errors.otherService}
            />
          )}
          {/* General Info */}
          {(formik.values.isRequiredDocuments ||
            currentStep?.key ===
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
            currentStep?.key ===
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' ||
            currentStep?.key ===
              'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY') && (
            <>
              <InputComtrol
                id={'documentsNames'}
                label={titleDocument || 'Añadir documento (opcional)'}
                isRequired={formik.values.isRequiredDocuments}
              >
                <UploadFile
                  id={'documentsNames'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  multiple={true}
                  onChange={(e) => handleChangeDocuments(e)}
                  error={Boolean(formik.errors.documentsNames)}
                  errorText={formik.errors.documentsNames}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.documentsNames.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteFile(item?.name)}
                  />
                ))}
              </Flex>
            </>
          )}
          {formik.values.verificationOfDocuments === 'APPROVED' &&
            currentStep?.key ===
              'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING' && (
              <>
                <InputComtrol
                  id={'documentsNames'}
                  label="Agregar oficio firmado"
                  isRequired={formik.values.isRequiredVerificationOfDocuments}
                >
                  <UploadFile
                    id={'documentsNames'}
                    direction={'row'}
                    sizeIcon={30}
                    p={5}
                    multiple={true}
                    onChange={(e) => handleChangeDocuments(e)}
                    error={Boolean(formik.errors.documentsNames)}
                    errorText={formik.errors.documentsNames}
                  />
                </InputComtrol>
                <Flex direction={'column'} gap={1}>
                  {formik.values.documentsNames.map((item) => (
                    <BarFileName
                      key={item?.name}
                      fileName={item?.name}
                      fileUrl={item?.url}
                      onDelete={() => deleteFile(item?.name)}
                    />
                  ))}
                </Flex>
              </>
            )}

          <TextAreaComtrol
            label={titleObservation || 'Observaciones (opcional)'}
            id={'descriptions'}
            isRequired={formik.values.isRequiredDescriptions}
            value={formik.values.descriptions}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.descriptions)}
            errorText={formik.errors.descriptions}
          />

          {formik.values.isRequiredMoreDescriptions && (
            <RenderArrayDescriptions formik={formik} />
          )}

          <RenderCheckRequired formik={formik} />

          {!requiredDocuments.includes(currentStep?.key) &&
            currentStep?.key !==
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' &&
            currentStep?.key !==
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' &&
            currentStep?.key !== 'STOCK-RECIEVED-NEWBORN-BENEFIT' && (
              <Checkbox
                css={{ borderColor: 'green' }}
                id={'isRequiredDocuments'}
                size="md"
                colorScheme="green"
                value={formik.values.isRequiredDocuments}
                onChange={formik.handleChange}
              >
                ¿Desea adjuntar documentos adicionales?
              </Checkbox>
            )}
          {/* end General Info */}
        </Box>
      </Flex>
    </ModalHistory>
  )
}

export default OtherProcesses
