import { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Checkbox } from '@chakra-ui/react'
import {
  InputComtrol,
  CustomDatePicker,
  Select,
  TextAreaComtrol
} from '../../UI'
import {
  ZonesList,
  ZonesTwoList,
  RelationshipList3,
  CivilStatusList,
  RecidenceCondition,
  GradesCarabineros,
  FormatRut,
  FormatCode
} from '../../../Utils'
import { steepOneSchema } from './Schemas'

const StepOne = ({
  stepOneForm,
  setStepOneForm,
  setStepTwoForm,
  refStepOne,
  next
}) => {
  const initialValues = {
    receptionDate: '',
    civilStatus: CivilStatusList.at(0) || '',
    region: ZonesList.getRegions().at(0),
    commune: ZonesList.getCommunes(ZonesList.getRegions().at(0)).at(0),
    address: '',
    // relationship: RelationshipList3.at(0) || '',
    partnerEmail: '',
    alternativePhone: '',
    residenceCondition: RecidenceCondition.at(0) || '',
    isPartnerZone: false,
    zone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    prefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    unit: stepOneForm.partnerEndowment || '',
    observations: '',
    /* partner field */
    partnerRut: '',
    partnerPhone: '',
    partnerGrade: GradesCarabineros.at(0),
    partnerCode: '',
    partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    partnerPrefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    partnerEndowment: stepOneForm.partnerEndowment || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepOneSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepOneForm({
        ...values
      })
      next()
    }
  })

  const handleChangeRegion = (value) => {
    formik.setFieldValue('region', value)
    formik.setFieldValue('commune', ZonesList.getCommunes(value).at(0))
  }

  const handleChangeCommune = (value) => {
    formik.setFieldValue('commune', value)
  }

  // const handleChangeRelationship = (value) => {
  //   formik.setFieldValue('relationship', value)
  // }

  const handleChangeCivilStatus = (value) => {
    formik.setFieldValue('civilStatus', value)
  }

  const handleChangeResidenceCondition = (value) => {
    formik.setFieldValue('residenceCondition', value)
  }

  const handleChangeReceptionDate = (date) => {
    formik.setFieldValue('receptionDate', date)
  }

  const handleChangePartnerGrade = (value) => {
    formik.setFieldValue('partnerGrade', value)
  }

  const hadleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }

  const hadleChangePartnerZone = (value) => {
    formik.setFieldValue('partnerZone', value)
    formik.setFieldValue(
      'partnerPrefecture',
      ZonesTwoList.getCommunes(value).at(0)
    )
  }

  const handleChangePartnerPrefecture = (value) => {
    formik.setFieldValue('partnerPrefecture', value)
  }

  const handleChangeRut = () => {
    if (formik.values.partnerRut) {
      const formatedRut = FormatRut(formik.values.partnerRut)
      formik.setFieldValue('partnerRut', formatedRut)
    }
  }

  const handleChangeIsPartnerZone = (e) => {
    const isChecked = e.target.checked

    if (isChecked) {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked,
        zone: formik.values.partnerZone,
        prefecture: formik.values.partnerPrefecture,
        unit: formik.values.partnerEndowment
      })
    } else {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked
      })
    }
  }

  const formatCode = () => {
    if (formik.values.partnerCode) {
      const formatedCode = FormatCode(formik.values.partnerCode)
      formik.setFieldValue('partnerCode', formatedCode)
    }
  }

  useEffect(() => {
    refStepOne.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      receptionDate: stepOneForm.receptionDate || '',
      civilStatus: stepOneForm.civilStatus || CivilStatusList.at(0),
      region: stepOneForm.region || ZonesList.getRegions().at(0),
      commune:
        stepOneForm.commune ||
        ZonesList.getCommunes(ZonesList.getRegions().at(0)).at(0),
      address: stepOneForm.address || '',
      partnerEmail: stepOneForm.partnerEmail || '',
      // relationship: stepOneForm.relationship || RelationshipList3.at(0),
      alternativePhone: stepOneForm.alternativePhone || '',
      residenceCondition:
        stepOneForm.residenceCondition || RecidenceCondition.at(0),
      isPartnerZone: stepOneForm.isPartnerZone || false,
      zone:
        stepOneForm.zone ||
        stepOneForm.partnerZone ||
        ZonesTwoList.getRegions().at(0),
      prefecture:
        stepOneForm.prefecture ||
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      unit: stepOneForm.unit || stepOneForm.partnerEndowment,
      observations: stepOneForm.observations || '',
      /* partner field */
      partnerRut: stepOneForm.partnerRut || '',
      partnerPhone: stepOneForm.partnerPhone || '',
      partnerGrade: stepOneForm.partnerGrade || GradesCarabineros.at(0),
      partnerCode: stepOneForm.partnerCode || '',
      partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
      partnerPrefecture:
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      partnerEndowment: stepOneForm.partnerEndowment || ''
    })
  }, [stepOneForm])

  return (
    <>
      {/* partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del Socio (Opcionales)
          </Text>
          <InputComtrol
            id={'partnerRut'}
            label={'Rut Socio'}
            isRequired={false}
            value={formik.values.partnerRut}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.partnerRut)}
            errorText={formik.errors.partnerRut}
          />
          <InputComtrol
            id={'partnerPhone'}
            label={'Teléfono Socio'}
            isRequired={false}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.partnerPhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerPhone)}
            errorText={formik.errors.partnerPhone}
          />
          <InputComtrol
            id={'partnerZone'}
            label={'Zona Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.partnerZone}
              onChange={hadleChangePartnerZone}
              error={Boolean(formik.errors.partnerZone)}
              errorText={formik.errors.partnerZone}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol
            id={'partnerCode'}
            label={'Código Socio, ej: 001001-D'}
            isRequired={false}
            value={formik.values.partnerCode}
            onChange={formik.handleChange}
            onBlur={formatCode}
            error={Boolean(formik.errors.partnerCode)}
            errorText={formik.errors.partnerCode}
          />
          <InputComtrol
            id={'partnerGrade'}
            label={'Grado Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={GradesCarabineros}
              value={formik.values.partnerGrade}
              onChange={handleChangePartnerGrade}
            />
          </InputComtrol>

          <InputComtrol
            id={'partnerPrefecture'}
            label={'Alta repartición / Repartición Socio'}
            isRequired={false}
            error={Boolean(formik.errors.partnerPrefecture)}
            errorText={formik.errors.partnerPrefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.partnerZone)}
              value={formik.values.partnerPrefecture}
              onChange={handleChangePartnerPrefecture}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
        mb={8}
      >
        <GridItem>
          <InputComtrol
            id={'partnerEndowment'}
            label={'Unidad Socio'}
            isRequired={false}
            value={formik.values.partnerEndowment}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerEndowment)}
            errorText={formik.errors.partnerEndowment}
          />
        </GridItem>
      </Grid>
      {/* end partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
        mb={8}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información adicional del socio
          </Text>
          <InputComtrol
            id={'receptionDate'}
            label={'Fecha de recepción de solicitud'}
          >
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.receptionDate}
              onChange={(date) => handleChangeReceptionDate(date)}
              error={Boolean(formik.errors.receptionDate)}
              errorText={formik.errors.receptionDate}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
          <InputComtrol id={'region'} label={'Seleccione una región'}>
            <Select
              bg={'white'}
              list={ZonesList.getRegions()}
              value={formik.values.region}
              onChange={handleChangeRegion}
            />
          </InputComtrol>
          <InputComtrol
            id={'address'}
            label={'Dirección'}
            value={formik.values.address}
            onChange={(e) => {
              formik.handleChange(e)
              setStepTwoForm((prev) => ({
                ...prev,
                addressPostulant: e.target.value
              }))
            }}
            error={Boolean(formik.errors.address)}
            errorText={formik.errors.address}
          />
          <InputComtrol
            isRequired={false}
            id={'alternativePhone'}
            label={'Teléfono Alternativo'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.alternativePhone}
            onChange={(e) => {
              formik.handleChange(e)
              setStepTwoForm((prev) => ({
                ...prev,
                phonePostulant: e.target.value
              }))
            }}
            error={Boolean(formik.errors.alternativePhone)}
            errorText={formik.errors.alternativePhone}
          />
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol id={'civilStatus'} label={'Estado Civil'}>
            <Select
              bg={'white'}
              list={CivilStatusList}
              value={formik.values.civilStatus}
              onChange={handleChangeCivilStatus}
            />
          </InputComtrol>
          <InputComtrol id={'commune'} label={'Seleccione una Comuna'}>
            <Select
              bg={'white'}
              list={ZonesList.getCommunes(formik.values.region)}
              value={formik.values.commune}
              onChange={handleChangeCommune}
            />
          </InputComtrol>
          <InputComtrol
            id={'partnerEmail'}
            label={'Correo electronico'}
            value={formik.values.partnerEmail}
            onChange={(e) => {
              formik.handleChange(e)
              setStepTwoForm((prev) => ({
                ...prev,
                emailPostulant: e.target.value
              }))
            }}
            error={Boolean(formik.errors.partnerEmail)}
            errorText={formik.errors.partnerEmail}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información habitacional
          </Text>
          <InputComtrol
            id={'residenceCondition'}
            label={'Condición de residencia'}
          >
            <Select
              bg={'white'}
              list={RecidenceCondition}
              value={formik.values.residenceCondition}
              onChange={handleChangeResidenceCondition}
            />
          </InputComtrol>
        </GridItem>
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
              Zona de postulación
            </Text>
          </GridItem>
          <GridItem>
            <Checkbox
              css={{ borderColor: 'green' }}
              id={'isPartnerZone'}
              size="md"
              colorScheme="green"
              isChecked={formik.values.isPartnerZone}
              value={formik.values.isPartnerZone}
              onChange={handleChangeIsPartnerZone}
              py={4}
              fontWeight={700}
            >
              ¿Es la zona actual del socio?
            </Checkbox>
          </GridItem>
          <GridItem>
            <InputComtrol id={'zone'} label={'Zona'}>
              <Select
                bg={'white'}
                list={ZonesTwoList.getRegions()}
                value={formik.values.zone}
                onChange={hadleChangeZone}
                error={Boolean(formik.errors.zone)}
                errorText={formik.errors.zone}
              />
            </InputComtrol>
          </GridItem>
          <GridItem>
            {/* <Text fontSize={'xl'} opacity={0}>
              -----
            </Text> */}
            <InputComtrol
              id={'prefecture'}
              label={'Alta repartición / Repartición'}
              error={Boolean(formik.errors.prefecture)}
              errorText={formik.errors.prefecture}
            >
              <Select
                bg={'white'}
                list={ZonesTwoList.getCommunes(formik.values.zone)}
                value={formik.values.prefecture}
                onChange={handleChangePrefecture}
              />
            </InputComtrol>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              id={'unit'}
              label={'Unidad'}
              value={formik.values.unit}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.unit)}
              errorText={formik.errors.unit}
            />
          </GridItem>
        </Grid>
        <GridItem>
          <TextAreaComtrol
            id={'observations'}
            isRequired={false}
            label={'Observación de residencia'}
            value={formik.values.observations}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observations)}
            errorText={formik.errors.observations}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepOne
