import React, { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  Tooltip,
  Avatar,
  Select,
  Button,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { CustomSlider } from '../UI'
import { ArrowStepIcon } from '../Icons'
import { FormatDate } from '../../Utils'
import CardItemProgram from './CardItemProgram'
import GetStatusCard from '../../Utils/GetStatusCard'
import DocumentsResumenModal from './DocumentsResumenModal'

const ProgramHistory = ({ history = [], state, type, program }) => {
  const [currentCard, setCurrentCard] = useState(0)
  const [itemFocus, setItemFocus] = useState(0)
  const [historySteps, setHistorySteps] = useState(history)
  const [requestSelected, setRequestSelected] = useState('')
  const [otherRequestSelected, setOtherRequestSelected] = useState('')
  const [historyStep, setHistoryStep] = useState([])
  const { activeStep } = useSteps({
    initialStep: 0
  })

  const validateHasIncompleteSteps = () => {
    if (!program?.stepsIncomplete.length > 0) {
      return GetStatusCard.program(state)
    }
    return (
      <Flex position={'relative'} display={'block'}>
        {GetStatusCard.program(state)}
        <Tooltip
          hasArrow
          label={'Tienes Pasos Incompletos'}
          bg={'red.500'}
          fontSize={'md'}
          placement={'left'}
        >
          <Avatar
            position={'absolute'}
            top={'-5px'}
            right={'-5px'}
            bg={'red.500'}
            borderRadius={'50%'}
            size={'xs'}
            name={'!'}
          />
        </Tooltip>
      </Flex>
    )
  }

  // useEffect(() => {
  //   if (history.length > 0) {
  //     let documentHistory = history
  //     if (requestSelected && !otherRequestSelected) {
  //       documentHistory = history.filter(
  //         (item) =>
  //           !item.documentFlow ||
  //           (item.documentFlow === requestSelected && !item.subDocumentFlow)
  //       )
  //     }

  //     if (otherRequestSelected && requestSelected) {
  //       documentHistory = history.filter(
  //         (item) =>
  //           !item.documentFlow ||
  //           (item.documentFlow === requestSelected && !item.subDocumentFlow) ||
  //           item.subDocumentFlow === otherRequestSelected
  //       )
  //     }

  //     setHistorySteps(documentHistory)
  //   }
  // }, [requestSelected, otherRequestSelected, history])

  // eliminar esto al descomentar el flujo del fondo solidario
  useEffect(() => {
    if (history.length > 0) {
      setHistorySteps(history)
    }
  }, [history])

  useEffect(() => {
    if (historySteps.length > 0) {
      // para ordenar el historial por tarea completada
      setHistoryStep(
        [...history].reverse().sort((a, b) => a.complete - b.complete)
      )

      setItemFocus(historySteps.findIndex((item) => item.complete === false))
      setCurrentCard(historySteps.findIndex((item) => item.complete === false))
    }
  }, [historySteps, history])

  const getColor = (CurrentState) => {
    if (CurrentState?.incompleteStep) {
      return 'blue'
    }

    if (CurrentState === 'APPROVED' || CurrentState === 'COMPLETED-APPROVED')
      return 'green'
    if (
      CurrentState === 'REJECTED' ||
      CurrentState === 'COMPLETED-REJECTED' ||
      CurrentState === 'DELETED'
    )
      return 'red'
    if (CurrentState === 'INREVIEW') return 'orange'
    if (CurrentState === 'INCOMPLETE') return 'grey'

    if (
      CurrentState === 'REVOKED' ||
      CurrentState === 'COMPLETED-REVOKED' ||
      CurrentState === 'DESISTED'
    ) {
      return 'yellow'
    }

    return ''
  }

  const labelSteep = (title, createdBy, updatedBy) => (
    <Flex direction={'column'} justify={'flex-start'} align={'start'}>
      <Tooltip label={title} placement="top-start" bg={'blue.100'}>
        <Text fontSize="md" fontWeight={700} noOfLines={1} textAlign={'left'}>
          {title}
        </Text>
      </Tooltip>
      <Text fontSize="sm" fontWeight={700}>
        {updatedBy
          ? `${updatedBy?.name} ${updatedBy?.lastName}`
          : `${createdBy?.name} ${createdBy?.lastName}`}
      </Text>
    </Flex>
  )

  // const handleDownloadRequest = () => {
  //   const aditionalInfo = program?.additionalInformation?.find(
  //     (info) => info.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
  //   )

  //   const request = aditionalInfo?.purchaseRequestLetter.find(
  //     (item) => item.newName === requestSelected
  //   )

  //   window.open(request.url, '_blank')
  // }

  // const handleDownloadOtherRequest = () => {
  //   const aditionalInfo = program?.additionalInformation?.find(
  //     (info) => info.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
  //   )

  //   const request = aditionalInfo?.documents.find(
  //     (item) => item.newName === requestSelected
  //   )

  //   window.open(request.url, '_blank')
  // }

  // const mustSelectInitialPurchaseOrder =
  //   program?.type === 'SolidarityHealthFund' &&
  //   history.length > 10 &&
  //   !requestSelected

  // const mustSelectPurchaseOrder =
  //   !otherRequestSelected &&
  //   history.find(
  //     (item) =>
  //       item.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
  //       item.documentFlow === requestSelected
  //   )?.complete

  // const multipleStepFinish = history.find(
  //   (item) =>
  //     item.key === 'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND'
  // )?.complete

  return (
    <>
      <Flex justify={'space-between'} py={5} px={2}>
        <Text fontWeight={700} fontSize={'2xl'} color={'grey.500'}>
          Estado de solicitud
        </Text>
        {validateHasIncompleteSteps()}
      </Flex>
      {/* {program.type === 'SolidarityHealthFund' && (
        <DocumentsResumenModal currentProgram={program} />
      )}
      {!multipleStepFinish &&
        history.some(
          (item) =>
            item.key ===
            'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
        ) && (
          <Flex justify={'space-between'} alignItems="end" pb={2} px={2}>
            <FormControl>
              <FormLabel>Solicitud de ordenes de compras</FormLabel>
              <Select
                value={requestSelected}
                onChange={(e) => {
                  setOtherRequestSelected('')
                  setRequestSelected(e.target.value)
                }}
                placeholder="Seleccione un documento"
              >
                {program?.additionalInformation
                  ?.find(
                    (info) =>
                      info.key ===
                      'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
                  )
                  ?.purchaseRequestLetter.map((purchaseRequest, index) => (
                    <option
                      key={purchaseRequest.newName}
                      value={purchaseRequest.newName}
                    >
                      {purchaseRequest.name} -{' '}
                      {
                        history
                          .filter(
                            (item) =>
                              item.documentFlow === purchaseRequest.newName
                          )
                          .at(-1)?.title
                      }
                    </option>
                  ))}
              </Select>
            </FormControl>
            <Button
              colorScheme="teal"
              ml={1}
              onClick={handleDownloadRequest}
              isDisabled={!requestSelected}
            >
              Descargar
            </Button>
          </Flex>
        )}

      {requestSelected &&
        history.find(
          (item) =>
            item.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
            item.documentFlow === requestSelected
        )?.complete && (
          <Flex justify={'space-between'} alignItems="end" pb={2} px={2}>
            <FormControl>
              <FormLabel>Ordenes de compras</FormLabel>
              <Select
                value={otherRequestSelected}
                onChange={(e) => {
                  setOtherRequestSelected(e.target.value)
                }}
                placeholder="Seleccione una orden de compra"
              >
                {program?.additionalInformation
                  ?.find(
                    (info) =>
                      info.key ===
                        'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
                      info.documentSelected === requestSelected
                  )
                  ?.documents.map((purchaseRequest) => (
                    <option
                      key={purchaseRequest.newName}
                      value={purchaseRequest.newName}
                    >
                      {purchaseRequest.name} -{' '}
                      {
                        history
                          .filter(
                            (item) =>
                              item.subDocumentFlow === purchaseRequest.newName
                          )
                          .at(-1)?.title
                      }
                    </option>
                  ))}
              </Select>
            </FormControl>
            <Button
              colorScheme="teal"
              ml={1}
              onClick={handleDownloadOtherRequest}
              isDisabled={!otherRequestSelected}
            >
              Descargar
            </Button>
          </Flex>
        )}

      {!multipleStepFinish && mustSelectPurchaseOrder && (
        <Flex
          height={200}
          borderWidth={1}
          borderRadius={12}
          alignItems="center"
          justifyContent="center"
        >
          <Text align="center">
            Seleccione una orden de compra para continuar
          </Text>
        </Flex>
      )}

      {!multipleStepFinish && mustSelectInitialPurchaseOrder && (
        <Flex
          height={200}
          borderWidth={1}
          borderRadius={12}
          alignItems="center"
          justifyContent="center"
        >
          <Text align="center">
            Seleccione una solicitud de orden de compra para continuar
          </Text>
        </Flex>
      )}

      {((!mustSelectPurchaseOrder && !mustSelectInitialPurchaseOrder) ||
        multipleStepFinish) && (
        <CustomSlider
          selectedItem={itemFocus}
          onChange={(e) => setCurrentCard(e)}
        >
          {historySteps?.map((item, index) => (
            <CardItemProgram
              key={item?.date}
              program={program}
              state={state}
              currentStep={item}
              index={index}
              currentCard={currentCard}
              type={type}
              requestSelected={requestSelected}
              otherRequestSelected={otherRequestSelected}
            />
          ))}
        </CustomSlider>
      )} */}

      <CustomSlider
        selectedItem={itemFocus}
        onChange={(e) => setCurrentCard(e)}
      >
        {historySteps?.map((item, index) => (
          <CardItemProgram
            key={item?.date}
            program={program}
            state={state}
            currentStep={item}
            index={index}
            currentCard={currentCard}
            type={type}
            // requestSelected={requestSelected}
            // otherRequestSelected={otherRequestSelected}
          />
        ))}
      </CustomSlider>
      <Flex direction={'column'} my={3} px={7}>
        <Steps
          activeStep={activeStep}
          orientation="vertical"
          responsive
          mb={{
            lg: 5,
            md: 0
          }}
          colorScheme={getColor(state)}
        >
          {historyStep.map(
            ({
              step,
              title,
              complete,
              createdBy,
              updatedBy,
              updatedAt,
              date,
              key
            }) => (
              <Step
                opacity={complete && 0.4}
                icon={ArrowStepIcon}
                key={step}
                label={labelSteep(title, createdBy, updatedBy)}
                description={FormatDate(updatedAt || date)}
                labelOrientation={'horizontal'}
                isCompletedStep={complete || key === 'FINISHED'}
              ></Step>
            )
          )}
        </Steps>
      </Flex>
    </>
  )
}

export default ProgramHistory
