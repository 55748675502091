import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, GridItem, Tr, Td, Flex, Icon } from '@chakra-ui/react'

import { Table, Spinner, Alert } from '../UI'
import { RedirectIcon } from '../Icons'
import { useProgramsPaginate } from '../../hooks/Programs'
import { FormatDate, ClearFormatMoney, FormatMoney } from '../../Utils'
import GetStatusCard from '../../Utils/GetStatusCard'

import DetailsModal from './DetailsModal'

const TableHistoricalPartners = ({ filtersPrograms }) => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const limit = 6
  const {
    data: historicalList,
    isLoading,
    isFetching,
    isError
  } = useProgramsPaginate(filtersPrograms, page, limit)
  const headers = [
    '#',
    'Fecha de Solicitud',
    'Fecha de recepción',
    'Programas',
    'Status de Solicitud',
    'Beneficiario',
    'Total transferido',
    'Valor estimado de ayuda',
    'Info',
    'Ver Solicitud'
  ]

  const toProgramDetail = (program) => {
    history.push(`/programs/view/${program._id}/${program.type}`)
  }

  const getTransferredAmount = (program) => {
    let totalTransferredAmount = '0'
    const { flowData } = program
    if (flowData?.length > 0) {
      totalTransferredAmount = flowData?.reduce(
        (acc, i) => acc + ClearFormatMoney(i?.transferredAmount),
        0
      )
    }
    return FormatMoney(totalTransferredAmount)
  }

  const getUsedAmount = (program) => {
    let totalEstimatedAmount = '0'
    const { flowData } = program
    if (flowData?.length > 0) {
      totalEstimatedAmount = flowData?.reduce(
        (acc, i) => acc + ClearFormatMoney(i?.usedAmount),
        0
      )
    }
    return FormatMoney(totalEstimatedAmount)
  }

  const renderBeneficiaries = (programData) => {
    const { id } = filtersPrograms

    if (programData?.type === 'WorkshopsAndTraining') {
      const findPartner = programData?.initialRegisteredPartners?.find(
        (r) => r.partner === id
      )

      if (!findPartner) {
        return 'No Aplica'
      }

      if (findPartner.spouse) {
        return 'Socio(a)/Cónyuge'
      }

      return 'Socio(a)'
    }

    return (
      programData?.beneficiariesHelped?.map((b) => b).join('/') || 'No Aplica'
    )
  }

  return (
    <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
      <Flex w={'100%'} justify={'flex-end'} py={3}>
        <Spinner size={'lg'} show={isLoading || isFetching} />
      </Flex>
      {isError && (
        <Flex w={'100%'} justify={'flex-end'} py={3}>
          <Alert status={'error'} message={'Error al Cargar los Datos'} />
        </Flex>
      )}
      <GridItem
        overflowX={'auto'}
        display={{
          lg: 'table',
          md: 'break-word',
          sm: 'break-word'
        }}
        css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#2E7423',
            borderRadius: '20px'
          }
        }}
      >
        <Table
          headers={headers}
          totalRecords={historicalList?.totalRecords}
          setPage={setPage}
          page={page}
        >
          {historicalList?.currentPages?.map((item, index) => (
            <Tr key={item._id}>
              <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                {index + 1 + page * limit}
              </Td>
              <Td color={'grey.500'}>{FormatDate(`${item?.createdAt}`)}</Td>
              <Td color={'grey.500'}>{FormatDate(`${item?.receptionDate}`)}</Td>
              <Td color={'grey.500'}>{item?.programName}</Td>
              <Td color={'grey.500'}>
                {GetStatusCard.programTable(item?.state)}
              </Td>
              <Td color={'grey.500'} fontWeight={700}>
                {renderBeneficiaries(item)}
              </Td>
              <Td color={'grey.500'} fontWeight={700}>
                {getTransferredAmount(item)}
              </Td>
              <Td color={'grey.500'} fontWeight={700}>
                {getUsedAmount(item)}
              </Td>
              <Td textAlign={'center'}>
                <DetailsModal currentProgram={item} />
              </Td>
              <Td textAlign={'center'}>
                <Icon
                  fontSize="26"
                  as={RedirectIcon}
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => toProgramDetail(item)}
                />
              </Td>
            </Tr>
          ))}
        </Table>
      </GridItem>
    </Grid>
  )
}

export default TableHistoricalPartners
