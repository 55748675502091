/* eslint-disable import/prefer-default-export */
import { saveAs } from 'file-saver'
import axios from '../../Axios'
import { module } from './module'

const typeForm = '/workshops-and-training'

export const createNewProgram = async (formData) => {
  const { data } = await axios.post(`${module}${typeForm}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const addNewMonitorProgram = async (programId, formData) => {
  const { data } = await axios.post(
    `${module}${typeForm}/${programId}/aditional-monitor`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const updateProgram = async ({ programId, formData }) => {
  const { data } = await axios.patch(
    `${module}/${typeForm}/${programId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const addRegisteredToProgram = async ({ programId, formData }) => {
  const { data } = await axios.patch(
    `${module}/${typeForm}/add-registered-to-program/${programId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const downloadRegistered = async (body) => {
  const response = await axios.post(
    `${module}/${typeForm}/generate-registered-file`,
    body,
    {
      responseType: 'arraybuffer'
    }
  )
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}
