import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Checkbox, Tooltip } from '@chakra-ui/react'
import { InputComtrol, SquareButton, Select, Button } from '../../../UI'
import { steepThreeSchema } from '../Schemas'
import {
  Age,
  ClearFormatMoney,
  FormatMoney,
  RelationshipList3,
  ScholarshipList,
  ForecastList
} from '../../../../Utils'
import HealthForm from './HealthForm'

const FamilyMemberCard = ({
  _id,
  onClick,
  members,
  member,
  handleUpdateMember
}) => {
  const initialValues = {
    _id: '',
    memberName: '',
    memberAge: Age.at(0),
    memberRelationship: RelationshipList3.at(0),
    scholarship: ScholarshipList.at(0),
    memberActivity: '',
    memberIncomes: '',
    memberForecast: ForecastList.at(0),
    /* --- health data --- */
    haveMedicalHistory: false,
    doctorName: '',
    hospitalName: '',
    monthlyExpenditure: '',
    diagnostic: '',
    evolutionTime: '',
    observation: '',
    medicalHistoryName: [],
    medicalHistory: null,
    state: 'READY'
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: steepThreeSchema,
    onSubmit: (values) => {
      handleUpdateMember(_id, values)
    }
  })

  const handleChangeScholarship = (value) => {
    formik.setFieldValue('scholarship', value)
  }

  const handleChangeForecast = (value) => {
    formik.setFieldValue('memberForecast', value)
  }

  const handleChangeAge = (value) => {
    formik.setFieldValue('memberAge', value.toString())
  }

  const handleChangeRelationship = (value) => {
    formik.setFieldValue('memberRelationship', value)
  }

  const formatIncomes = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.memberIncomes))
    formik.setFieldValue('memberIncomes', format)
  }

  const isValidMemberItem = () => {
    const isValid = Boolean(members.find((item) => item._id === _id).updated)
    const isEqualsMemberName = Boolean(
      members.find((item) => item._id === _id).memberName ===
        formik.values.memberName
    )
    const isEqualsMemberAge = Boolean(
      members.find((item) => item._id === _id).memberAge ===
        formik.values.memberAge
    )
    const isEqualsMemberRelationship = Boolean(
      members.find((item) => item._id === _id).memberRelationship ===
        formik.values.memberRelationship
    )
    const isEqualsIsScholarship = Boolean(
      members.find((item) => item._id === _id).scholarship ===
        formik.values.scholarship
    )
    const isEqualsMemberActivity = Boolean(
      members.find((item) => item._id === _id).memberActivity ===
        formik.values.memberActivity
    )
    const isEqualsMemberIncomes = Boolean(
      members.find((item) => item._id === _id).memberIncomes ===
        formik.values.memberIncomes
    )
    const isEqualsMemberForecast = Boolean(
      members.find((item) => item._id === _id).memberForecast ===
        formik.values.memberForecast
    )
    /* --- health data --- */
    const isEqualsHaveMedicalHistory = Boolean(
      members.find((item) => item._id === _id).haveMedicalHistory ===
        formik.values.haveMedicalHistory
    )
    const isEqualsDoctorName = Boolean(
      members.find((item) => item._id === _id).doctorName ===
        formik.values.doctorName
    )
    const isEqualsHospitalName = Boolean(
      members.find((item) => item._id === _id).hospitalName ===
        formik.values.hospitalName
    )
    const isEqualsMonthlyExpenditure = Boolean(
      members.find((item) => item._id === _id).monthlyExpenditure ===
        formik.values.monthlyExpenditure
    )
    const isEqualsDiagnostic = Boolean(
      members.find((item) => item._id === _id).diagnostic ===
        formik.values.diagnostic
    )
    const isEqualsEvolutionTime = Boolean(
      members.find((item) => item._id === _id).evolutionTime ===
        formik.values.evolutionTime
    )
    const isEqualsObservation = Boolean(
      members.find((item) => item._id === _id).observation ===
        formik.values.observation
    )
    const isEqualsMedicalHistoryName = Boolean(
      members.find((item) => item._id === _id).medicalHistoryName.length ===
        formik.values.medicalHistoryName.length
    )

    if (
      isValid &&
      isEqualsMemberName &&
      isEqualsMemberAge &&
      isEqualsMemberRelationship &&
      isEqualsIsScholarship &&
      isEqualsMemberActivity &&
      isEqualsMemberIncomes &&
      isEqualsMemberForecast &&
      isEqualsHaveMedicalHistory &&
      isEqualsDoctorName &&
      isEqualsHospitalName &&
      isEqualsMonthlyExpenditure &&
      isEqualsDiagnostic &&
      isEqualsEvolutionTime &&
      isEqualsObservation &&
      isEqualsMedicalHistoryName
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    const setErrors = Object.keys(formik.errors).length
    if (setErrors > 0 && !formik.isSubmitting) {
      handleUpdateMember(_id, {}, false)
    }
  }, [formik.isSubmitting])

  useEffect(() => {
    if (member) {
      formik.setValues({
        _id: member?._id || Date.now(),
        memberName: member?.memberName || '',
        memberAge: member?.memberAge || Age.at(0),
        memberRelationship:
          member?.memberRelationship || RelationshipList3.at(0),
        scholarship: member?.scholarship || ScholarshipList.at(0),
        memberActivity: member?.memberActivity || '',
        memberIncomes: member?.memberIncomes || '',
        memberForecast: member?.memberForecast || ForecastList.at(0),
        /* --- health data --- */
        haveMedicalHistory: member?.haveMedicalHistory || false,
        doctorName: member?.doctorName || '',
        hospitalName: member?.hospitalName || '',
        monthlyExpenditure: member?.monthlyExpenditure || '',
        diagnostic: member?.diagnostic || '',
        evolutionTime: member?.evolutionTime || '',
        observation: member?.observation || '',
        medicalHistoryName: member?.medicalHistoryName || [],
        medicalHistory: member?.medicalHistory || null,
        state: member?.state || 'READY'
      })
    }
  }, [member])

  return (
    <Flex
      direction={'column'}
      border={'solid 1px #2E7423'}
      bg={isValidMemberItem() ? '#F0FFF4' : '#FFFFF0'}
      borderRadius={18}
      p={6}
      my={1}
      gap={1}
    >
      <Flex justify={'flex-end'}>
        <SquareButton onClick={() => onClick(_id)} size={'xs'}>
          x
        </SquareButton>
      </Flex>
      <Flex
        gap={1}
        direction={{
          lg: 'row',
          md: 'row',
          sm: 'column'
        }}
      >
        <InputComtrol
          id={'memberName'}
          label={'Nombre Completo'}
          value={formik.values.memberName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.memberName)}
          errorText={formik.errors.memberName}
        />
        <InputComtrol id={'memberAge'} label={'Edad'}>
          <Select
            bg={'white'}
            list={Age}
            value={formik.values.memberAge}
            onChange={handleChangeAge}
          />
        </InputComtrol>

        <InputComtrol id={'memberRelationship'} label={'Parentesco'}>
          <Select
            bg={'white'}
            list={RelationshipList3}
            value={formik.values.memberRelationship}
            onChange={handleChangeRelationship}
          />
        </InputComtrol>
      </Flex>
      <Flex
        gap={1}
        direction={{
          lg: 'row',
          md: 'row',
          sm: 'column'
        }}
      >
        {/* <InputComtrol id={'scholarship'} label={'Escolaridad'}>
          <Select
            bg={'white'}
            list={ScholarshipList}
            value={formik.values.scholarship}
            onChange={handleChangeScholarship}
          />
        </InputComtrol> */}
        <InputComtrol
          id={'memberActivity'}
          label={'Actividad'}
          value={formik.values.memberActivity}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.memberActivity)}
          errorText={formik.errors.memberActivity}
        />
        <InputComtrol
          id={'memberIncomes'}
          label={'Ingresos'}
          placeholder={'$'}
          type={'currency'}
          isRequired={false}
          value={formik.values.memberIncomes}
          onChange={formik.handleChange}
          onBlur={formatIncomes}
          error={Boolean(formik.errors.memberIncomes)}
          errorText={formik.errors.memberIncomes}
        />
      </Flex>
      {/* <InputComtrol id={'memberForecast'} label={'Previsión'}>
        <Select
          bg={'white'}
          list={ForecastList}
          value={formik.values.memberForecast}
          onChange={handleChangeForecast}
        />
      </InputComtrol> */}
      {/* --- health data --- */}
      <Checkbox
        css={{ borderColor: 'green' }}
        id={'haveMedicalHistory'}
        size="md"
        colorScheme="green"
        isChecked={formik.values.haveMedicalHistory}
        value={formik.values.haveMedicalHistory}
        onChange={formik.handleChange}
        py={4}
      >
        ¿Este miembro posee algún antecedente médico de relevancia?
      </Checkbox>
      {formik.values.haveMedicalHistory && (
        <HealthForm formik={formik} _id={_id} />
      )}
      <Flex justify={'flex-end'}>
        <Tooltip
          label="Favor Validar! ->"
          placement="left"
          bg={'#d68c49'}
          isOpen={!isValidMemberItem()}
        >
          <Flex>
            <Button
              text={isValidMemberItem() ? 'Validado' : 'No validado'}
              py={1}
              px={2}
              onClick={formik.handleSubmit}
              size={'xs'}
            />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default FamilyMemberCard
