import React from 'react'
import { Table as TableCha, Thead, Tr, Th, Tbody } from '@chakra-ui/react'
import CustomPaginate from './CustomPaginate'

const Table = ({
  headers = [],
  totalRecords = 0,
  page,
  setPage,
  children,
  size = 'md',
  limit = 6
}) => (
  <>
    <TableCha variant={'simple'} size={size}>
      <Thead css={{ background: '#F1ECE2' }}>
        <Tr>
          {headers.map((title) => (
            <Th style={{ color: '#2E7423' }}>{title}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{children}</Tbody>
    </TableCha>

    {totalRecords > 0 && (
      <CustomPaginate
        totalRecords={totalRecords}
        page={page}
        setPage={setPage}
        perPage={limit}
      />
    )}
  </>
)

export default Table
