import {
  CheckIcon,
  MinusIcon,
  RejectedIcon,
  ReplayIcon
} from '../components/Icons'

/* eslint-disable import/prefer-default-export */
export const keyDictionary = {
  'STOCK-RECIEVED-NEWBORN-BENEFIT': {
    title: 'Recepción de inventario',
    question:
      '¿Desea hacer la Recepción de inventario para todos los aprobados de este programa?',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La evaluación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la evaluación',
    titleDocument: 'Añadir documento (opcional)',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT'
    },
    isEditable: false
  },
  'GIFTCARD-DELIVERY-NEWBORN-BENEFIT': {
    title: 'Entrega de giftcard nominadas solicitadas',
    question:
      '¿Desea hacer Entrega de giftcard nominadas para todos los aprobados de este programa?',
    // actionKey: 'OTHER-PROCESSES',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El beneficio ha sido entregado con Éxito',
    errorMessage: 'Error al entregar el beneficio',
    titleDocument: 'Añadir nomina',
    keyToSearch: {
      one: 'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT'
    }
  },
  'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': {
    title: 'Solicitud nominación de giftcard',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    titleDocument: 'Nominación de giftcard',
    succesMessage: 'La solicitud de giftcard ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de giftcard',
    question:
      '¿Desea añadir la nominación para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': {
    title: 'Solicitud de desbloqueo y nominación giftcard',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    titleDocument: 'Subir nominación de giftcard',
    succesMessage: 'La solicitud de giftcard ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de giftcard',
    question:
      '¿Desea añadir la nominación para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'GIFTCARD-RECEPTION-NEWBORN-BENEFIT': {
    title: 'Envío y recepción de giftcards',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El beneficio ha sido recibido con Éxito',
    errorMessage: 'Error al recibir el beneficio',
    titleDocument: 'Añadir documentos'
  },
  'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT': {
    title: 'Activación de beneficio y proceso de inventario',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El beneficio ha sido activado con Éxito',
    errorMessage: 'Error al activar el beneficio',
    titleDocument: 'Añadir documentos',
    question:
      '¿Desea añadir el inventario para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 1',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'El oficio de solicitud de pago Nº 1 ha sido solicitado con Éxito',
    errorMessage: 'Error al solicitar el oficio de solicitud de pago Nº 1',
    titleDocument: 'Añadir oficio'
  },
  'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': {
    title: 'Verificación de solicitud de pago Nº 1',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añadir Oficio firmado',
    succesMessage:
      'El oficio de solicitud de pago Nº 1 ha sido Validado con Éxito',
    errorMessage: 'Error al Validar el oficio de solicitud de pago Nº 1',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER',
      two: 'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER',
      three: 'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER'
    }
  },
  'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': {
    title: 'Autorización de solicitud de pago Nº 1',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añadir Oficio firmado',
    succesMessage:
      'El oficio de solicitud de pago Nº 1 ha sido Validado con Éxito',
    errorMessage: 'Error al Validar el oficio de solicitud de pago Nº 1',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED'],
      textRightExtra: 'Corregir',
      iconRightExtra: MinusIcon,
      colorLeftExtra: 'yellow',
      optionRightExtra: ['EDIT']
    },
    keyToSearch: {
      one: 'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER',
      two: 'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER',
      three: 'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER'
    }
  },
  'PAYMENT-ONE-MADE-DEATH-OF-MEMBER': {
    title: 'Documentación de pago Nº 1',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El pago Nº 1 se ha documentado con Éxito',
    errorMessage: 'Error al documentar el pago Nº 1',
    titleDocument: 'Añadir comprobante de transferencia'
  },
  'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 2',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'El oficio de solicitud de pago Nº 2 ha sido solicitado con Éxito',
    errorMessage: 'Error al solicitar el oficio de solicitud de pago Nº 2',
    titleDocument: 'Añadir oficio'
  },
  'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': {
    title: 'Verificación de solicitud de pago Nº 2',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añadir Oficio firmado',
    succesMessage:
      'El oficio de solicitud de pago Nº 2 ha sido Validado con Éxito',
    errorMessage: 'Error al Validar el oficio de solicitud de pago Nº 2',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER',
      two: 'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER'
    }
  },
  'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': {
    title: 'Autorización de solicitud de pago Nº 2',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añadir Oficio firmado',
    succesMessage:
      'El oficio de solicitud de pago Nº 2 ha sido Validado con Éxito',
    errorMessage: 'Error al Validar el oficio de solicitud de pago Nº 2',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED'],
      textRightExtra: 'Corregir',
      iconRightExtra: MinusIcon,
      colorLeftExtra: 'yellow',
      optionRightExtra: ['EDIT']
    },
    keyToSearch: {
      one: 'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER',
      two: 'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
    }
  },
  'PAYMENT-TWO-MADE-DEATH-OF-MEMBER': {
    title: 'Documentación de pago Nº 2',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El pago Nº 2 se ha documentado con Éxito',
    errorMessage: 'Error al documentar el pago Nº 2',
    titleDocument: 'Añadir comprobante de transferencia'
  },
  'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 1 (Reenviar)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'El oficio de solicitud de pago Nº 1 ha sido reenviado con Éxito',
    errorMessage: 'Error al reenviar el oficio de solicitud de pago Nº 1',
    titleDocument: 'Añadir oficio',
    keyToSearch: {
      one: 'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER',
      two: 'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER'
    }
  },
  'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 2 (Reenviar)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'El oficio de solicitud de pago Nº 2 ha sido reenviado con Éxito',
    errorMessage: 'Error al reenviar el oficio de solicitud de pago Nº 2',
    titleDocument: 'Añadir oficio',
    keyToSearch: {
      one: 'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
    }
  },
  'COMMISSION-PREPARATION': {
    title: 'Preparación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La comisión ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la comisión',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-APPLICANT-OFFICER': {
    title: 'Preparación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La comisión ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la comisión',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-APPLICANT-OFFICER-DAC': {
    title: 'Preparación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La comisión ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la comisión',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-PRE-UNIVERSITY': {
    title: 'Preparación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La comisión ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la comisión',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL': {
    title: 'Preparación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La comisión ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la comisión',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': {
    title: 'Verificación de Oficio',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Oficio verificada con Éxito',
    errorMessage: 'Error al verificar oficio',
    titleObservation: 'Reseña social del caso'
  },
  'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY': {
    title: 'Informe Preuniversitario y solicitar monto a cancelar',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud ha sido enviado con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir Documento'
  },
  'PURCHASE-ORDER-REQUEST': {
    title: 'Solicitud de orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio de solicitud de orden de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar el oficio de solicitud de orden de compra',
    question:
      '¿Desea añadir el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': {
    title: 'Solicitud de orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio de solicitud de orden de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar el oficio de solicitud de orden de compra',
    question:
      '¿Desea añadir el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'PURCHASE-ORDER-REQUEST-EDIT': {
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio de solicitud de orden de compra ha sido reenviado, n° de registros actualizados: ',
    errorMessage: 'Error al reenviar el oficio de solicitud de orden de compra',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY': {
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio de solicitud de orden de compra ha sido reenviado, n° de registros actualizados: ',
    errorMessage: 'Error al reenviar el oficio de solicitud de orden de compra',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST': {
    title: 'Verificación de oficio',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio',
    question:
      '¿Desea verificar el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE',
      two: 'OFFICE-AND-INVOICE-EDIT',
      three: 'PURCHASE-ORDER-REQUEST',
      four: 'PURCHASE-ORDER-REQUEST-EDIT'
    }
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': {
    title: 'Verificación de oficio',
    titleDocument: 'Añadir oficio firmado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio',
    question:
      '¿Desea verificar el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE-APPLICANT-OFFICER',
      two: 'OFFICE-AND-INVOICE-EDIT-APPLICANT-OFFICER',
      three: 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER',
      four: 'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER'
    }
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Verificación de oficio',
    titleDocument: 'Añadir oficio firmado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio',
    question:
      '¿Desea verificar el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE-APPLICANT-OFFICER-DAC',
      two: 'OFFICE-AND-INVOICE-EDIT-APPLICANT-OFFICER-DAC',
      three: 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC',
      four: 'PURCHASE-ORDER-REQUEST-EDIT-APPLICANT-OFFICER-DAC'
    }
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL': {
    title: 'Verificación de oficio',
    titleDocument: 'Adjuntar oficio firmado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio',
    question:
      '¿Desea verificar el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL',
      two: 'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL',
      three: 'PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL',
      four: 'PURCHASE-ORDER-REQUEST-EDIT-TECHNICAL-PROFESSIONAL'
    }
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS': {
    title: 'Verificación de oficio',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio',
    question:
      '¿Desea verificar el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE-NEW-SEMESTERS',
      two: 'OFFICE-AND-INVOICE-EDIT-NEW-SEMESTERS',
      three: 'PURCHASE-ORDER-REQUEST-NEW-SEMESTERS',
      four: 'PURCHASE-ORDER-REQUEST-EDIT-NEW-SEMESTERS'
    }
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': {
    title: 'Verificación de oficio',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio',
    titleDocument: 'Añadir oficio firmado',
    question:
      '¿Desea verificar el oficio de solicitud de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY',
      two: 'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY',
      three: 'CORRECT-REQUEST-PRE-UNIVERSITY'
    }
  },
  'PURCHASE-ORDER-AUTORIZATION': {
    title: 'Autorización de confección de orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La autorización de confección de orden de compra ha sido enviada, n° de registros actualizados: ',
    errorMessage:
      'Error al enviar la autorización de confeción de orden de compra',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea autorizar la confección de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST',
      two: 'PURCHASE-ORDER-REQUEST-EDIT',
      three: 'CORRECT-REQUEST'
    }
  },
  'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY': {
    title: 'Autorización de confección de orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La autorización de confección de orden de compra ha sido enviada, n° de registros actualizados: ',
    errorMessage:
      'Error al enviar la autorización de confeción de orden de compra',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea autorizar la confección de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY',
      two: 'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY',
      three: 'CORRECT-REQUEST-PRE-UNIVERSITY',
      four: 'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY'
    }
  },
  'CORRECT-REQUEST-PRE-UNIVERSITY': {
    title: 'Subsanar solicitud',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir documento',
    question:
      '¿Desea subsanar la solicitud de todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY': {
    title: 'Orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La orden de compra ha sido enviada con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añadir orden de compra',
    question:
      '¿Desea añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT': {
    title: 'Orden de compra (a corregir)',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La orden de compra ha sido enviada con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añadir orden de compra',
    question:
      '¿Desea añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY': {
    title: 'Orden de compra Firmada',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La orden de compra ha sido enviada con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añadir orden de compra firmada',
    question:
      '¿Desea añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',

    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY',
      two: 'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT'
    }
  },

  'PAYMENT-MADE-PRE-UNIVERSITY': {
    title: 'Pago Realizado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir comprobante de pago',
    question:
      '¿Desea añadir el comprobante de pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY'
    }
  },
  'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY': {
    title: 'Informar el pago y solicitar factura',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido informado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al informar el pago',
    titleDocument: 'Añadir Documento',
    question:
      '¿Deseas actualizar el proceso para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'ATTACH-INVOICE-BY-PRE-UNIVERSITY': {
    title: 'Factura enviada por el preuniversitario',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La factura ha sido enviada con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la factura',
    titleDocument: 'Añadir factura',
    question:
      '¿Desea añadir la factura enviada por el preuniversitario para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'OFFICE-FOR-FINANCES-PRE-UNIVERSITY': {
    title: 'Oficio para finanzas',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio para finanzas ha sido enviado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar el oficio para finanzas',
    titleDocument: 'Añadir oficio para finanzas',
    question:
      '¿Desea añadir el oficio para finanzas en todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY': {
    title: 'Verificación de oficio para finanzas',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio para finanzas ha sido validado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al validar el oficio para finanzas',
    titleDocument: 'Añadir documentos',
    question:
      '¿Desea añadir verificación al oficio para finanzas para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    }
  },
  'OFFICE-FOR-FINANCES-PRE-UNIVERSITY-EDIT': {
    title: 'Oficio para finanzas (a corregir)',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El oficio para finanzas ha sido enviado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar el oficio para finanzas',
    titleDocument: 'Añadir oficio para finanzas',
    question:
      '¿Desea añadir el oficio para finanzas en todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'ADD-PROPOSAL-DISASTER-SUPPORT': {
    title: 'Oficio de propuesta',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de propuesta ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de propuesta',
    titleDocument: 'Añadir oficio'
  },
  'ADD-PROPOSAL-FIRE-SUPPORT': {
    title: 'Oficio de propuesta',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de propuesta ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de propuesta',
    titleDocument: 'Añadir oficio'
  },
  'LEGAL-REPORT-DISASTER-SUPPORT': {
    title: 'Informe jurídico: Sugerencia de determinación',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El informe jurídico ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el informe jurídico',
    /* titleDocument: 'Añadir informe', */
    titleObservation: 'Añadir observación',
    buttonsProps: {
      textLeft: '¡No cumple con los requisitos!',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: '¡Cumple con los requisitos!',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'ADD-PROPOSAL-DISASTER-SUPPORT'
    }
  },
  'LEGAL-REPORT-FIRE-SUPPORT': {
    title: 'Informe jurídico: Sugerencia de determinación',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El informe jurídico ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el informe jurídico',
    /* titleDocument: 'Añadir informe', */
    titleObservation: 'Añadir observación',
    buttonsProps: {
      textLeft: '¡No cumple con los requisitos!',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: '¡Cumple con los requisitos!',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'ADD-PROPOSAL-FIRE-SUPPORT'
    }
  },
  'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT': {
    title: 'Determinación de gerencia',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de gerencia ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de gerencia',
    titleDocument: 'Añadir documento',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'LEGAL-REPORT-DISASTER-SUPPORT',
      two: 'ADD-PROPOSAL-DISASTER-SUPPORT'
    }
  },
  'MANAGEMENT-DETERMINATION-FIRE-SUPPORT': {
    title: 'Determinación de gerencia',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de gerencia ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de gerencia',
    titleDocument: 'Añadir documento',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'LEGAL-REPORT-FIRE-SUPPORT',
      two: 'ADD-PROPOSAL-FIRE-SUPPORT'
    }
  },
  'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT': {
    title: 'Determinación de presidencia',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de presidencia ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de presidencia',
    titleDocument: 'Añadir documento',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    }
  },
  'PRESIDENSY-DETERMINATION-FIRE-SUPPORT': {
    title: 'Determinación de presidencia',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de presidencia ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de presidencia',
    titleDocument: 'Añadir documento',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    }
  },
  'INFORM-SERVICE-SOCIAL-DETERMINATION': {
    title: 'Informar a servicio social',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El informe de servicio social ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el informe de servicio social',
    titleDocument: 'Añadir documento'
  },
  'OFFICE-WITH-RESOLUTION': {
    title: 'Oficio con resolución',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio con resolución ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio con resolución',
    titleDocument: 'Añadir oficio'
  },
  'AID-APPROVAL-DISASTER-SUPPORT': {
    title: 'Oficio de aprobación de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de aprobacion de ayuda ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de aprobacion de ayuda',
    titleDocument: 'Añadir oficio'
  },
  'AID-APPROVAL-FIRE-SUPPORT': {
    title: 'Oficio de aprobación de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de aprobacion de ayuda ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de aprobacion de ayuda',
    titleDocument: 'Añadir oficio'
  },
  'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT': {
    title: 'Verificación de oficio de aprobación de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La verificación de oficio de aprobacion de ayuda ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la verificación de oficio de aprobacion de ayuda',
    titleDocument: 'Añadir documento',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'AID-APPROVAL-DISASTER-SUPPORT'
    }
  },
  'VALIDATE-AID-APPROVAL-FIRE-SUPPORT': {
    title: 'Verificación de oficio de aprobación de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La verificación de oficio de aprobacion de ayuda ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la verificación de oficio de aprobacion de ayuda',
    titleDocument: 'Añadir documento',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'AID-APPROVAL-FIRE-SUPPORT'
    }
  },
  'AID-APPROVAL-EDIT': {
    title: 'Oficio de aprobación de ayuda (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de aprobacion de ayuda ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de aprobacion de ayuda',
    titleDocument: 'Añadir oficio'
  },
  'AID-DEPOSIT-DISASTER-SUPPORT': {
    title: 'Depósito de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El depósito de ayuda ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el depósito de ayuda'
  },
  'AID-DEPOSIT-FIRE-SUPPORT': {
    title: 'Depósito de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El depósito de ayuda ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el depósito de ayuda'
  },
  'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT': {
    title: 'Notificar vía correo electrónico al Servicio Social',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La notificación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la notificación'
  },
  'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT': {
    title: 'Notificar vía correo electrónico al Servicio Social',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La notificación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la notificación'
  },
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT': {
    title: 'Acta de notificación firmada',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El acta de notificación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar el acta de notificación',
    titleDocument: 'Añade acta de notificación firmada por el socio y el becado'
  },
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL':
    {
      title: 'Acta de notificación firmada',
      actionKey: 'OTHER-PROCESSES',
      succesMessage: 'El acta de notificación ha sido enviada con Éxito',
      errorMessage: 'Error al enviar el acta de notificación',
      titleDocument:
        'Añade acta de notificación firmada por el socio y el becado'
    },
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY': {
    title: 'Acta de notificación firmada',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El acta de notificación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar el acta de notificación',
    titleDocument:
      'Añade acta de notificación firmada por el socio y el becado (Opcional)'
  },
  'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY': {
    title: 'Se informó a la universidad sobre el becado',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La notificación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la notificación'
  },
  'PURCHASE-ORDER-REQUEST-UNIVERSITY': {
    title: 'Solicitud de orden de compra',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud de orden de compra ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de orden de compra'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY': {
    title: 'Verificación de solicitud',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añadir documento',
    succesMessage:
      'La verificación de solicitud de orden ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la verificación de solicitud de orden',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-UNIVERSITY',
      two: 'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY',
      three: 'CORRECT-REQUEST-UNIVERSITY'
    }
  },
  'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY': {
    title: 'Solicitud de orden de compra (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud de orden de compra ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de orden de compra'
  },
  'PURCHASE-ORDER-AUTORIZATION-UNIVERSITY': {
    title: 'Autorización de confección de orden de compra',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La autorización de confección de orden de compra ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la autorización de confección de orden de compra',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-UNIVERSITY',
      two: 'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY'
    }
  },
  'GENERATE-PURCHASE-ORDER-UNIVERSITY': {
    title: 'Orden de compra para ser firmada',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añade orden de compra',
    succesMessage: 'La orden de compra ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la orden de compra',
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-UNIVERSITY',
      two: 'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY'
    }
  },
  'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY': {
    title: 'Orden de compra para ser firmada (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añade orden de compra',
    succesMessage: 'La orden de compra ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la orden de compra',
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-UNIVERSITY',
      two: 'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY'
    }
  },
  'SIGN-PURCHASE-ORDER-UNIVERSITY': {
    title: 'Orden de compra Firmada',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añade Orden de compra Firmada',
    succesMessage: 'La Información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la Información',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'GENERATE-PURCHASE-ORDER-UNIVERSITY',
      two: 'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY'
    }
  },
  'CORRECT-REQUEST-UNIVERSITY': {
    title: 'Subsanación de solicitud',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La subsanación de solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la subsanación de solicitud'
  },
  'PAYMENT-MADE-UNIVERSITY': {
    title: 'Pago realizado',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El comprobante de pago ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el comprobante de pago',
    titleDocument: 'Añade comprobante de pago'
  },
  'SOCIAL-ANALYSIS-UNIVERSITY': {
    title: 'Análisis social',
    actionKey: 'OTHER-PROCESSES',
    titleObservation: 'Reseña social del caso',
    succesMessage: 'El análisis social ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el análisis social'
  },
  'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY': {
    title: 'Informar a la universidad sobre el pago de aranceles',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la notificación'
  },
  'DELIVERY-OFFICE-UNIVERSITY': {
    title: 'Oficio de entrega',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de entrega ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de entrega',
    titleDocument: 'Añade oficio de entrega'
  },
  'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY': {
    title: 'Recepción de Factura',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La verificación de oficio de entrega ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la verificación de oficio de entrega',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'DELIVERY-OFFICE-UNIVERSITY',
      two: 'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY'
    }
  },
  'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY': {
    title: 'Oficio de entrega (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio de entrega ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio de entrega',
    titleDocument: 'Añade oficio de entrega'
  },
  'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL': {
    title: 'Monto a cancelar',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El monto a cancelar ha sido enviado con Éxito, n° de registros actualizados:',
    errorMessage: 'Error al enviar el monto a cancelar',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el monto a cancelar para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'REVIEW-OF-SUBMITTED-INFORMATION': {
    title: 'Revisión de información enviada por el preuniversitario',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La revisión de información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la revisión de información'
  },
  'AMOUNT-TO-CANCEL-NEW-SEMESTERS': {
    title: 'Monto a cancelar',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El monto a cancelar ha sido enviado con Éxito, n° de registros actualizados:',
    errorMessage: 'Error al enviar el monto a cancelar',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el monto a cancelar para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL': {
    title: 'Oficio y factura',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El oficio y factura ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio y factura',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio con factura para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'OFFICE-AND-INVOICE-NEW-SEMESTERS': {
    title: 'Oficio y factura',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El oficio y factura ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio y factura',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio con factura para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL': {
    title: 'Oficio y factura (a corregir)',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El oficio y factura ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio y factura',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio con factura para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT': {
    title: 'Oficio y factura (a corregir)',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El oficio y factura ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio y factura',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio con factura para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL': {
    title: 'Autorización de pago',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La autorización de pago ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la autorización de pago',
    question:
      '¿Desea autorizar el pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL'
    }
  },
  'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': {
    title: 'Autorización de pago',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La autorización de pago ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la autorización de pago',
    question:
      '¿Desea autorizar el pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS'
    }
  },
  'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL': {
    title: 'Subsanar solicitud',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir documento',
    question:
      '¿Desea subsanar la solicitud de todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': {
    title: 'Subsanar solicitud',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir documento',
    question:
      '¿Desea subsanar la solicitud de todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.'
  },
  'PAYMENT-MADE-TECHNICAL-PROFESSIONAL': {
    title: 'Pago realizado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El comprobante de pago ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el comprobante de pago',
    titleDocument: 'Añadir comprobante de pago',
    question:
      '¿Desea añadir el comprobante de pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL',
      two: 'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL'
    },
    isEditable: true
  },
  'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': {
    title: 'Pago realizado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El comprobante de pago ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el comprobante de pago',
    titleDocument: 'Añadir comprobante de pago',
    question:
      '¿Desea añadir el comprobante de pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'OFFICE-AND-INVOICE-NEW-SEMESTERS',
      two: 'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT'
    },
    isEditable: true
  },
  'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL': {
    title: 'Se informó al instituto del pago de arancel',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la información',
    question:
      '¿Desea actualizar el proceso para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS': {
    title: 'Se informó al instituto del pago de arancel',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la información',
    question:
      '¿Desea actualizar el proceso para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT': {
    title: 'Evaluación del depto. de control y gestión social',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La evaluación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la evaluación',
    titleDocument: 'Añadir documento (opcional)',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT': {
    title: 'Evaluación del depto. de control y gestión social',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La evaluación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la evaluación',
    titleDocument: 'Añadir documento (opcional)',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER': {
    title: 'Evaluación del depto. de control y gestión social',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La evaluación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la evaluación',
    titleDocument: 'Añadir documento (opcional)',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND': {
    title: 'Determinación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    titleObservation: 'Valor aportado (opcional)',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-UNIVERSITY': {
    title: 'Evaluación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-APPLICANT-OFFICER': {
    title: 'Evaluación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC': {
    title: 'Evaluación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL': {
    title: 'Evaluación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-PRE-UNIVERSITY': {
    title: 'Evaluación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'EVALUATION-COMMISSION-NEW-SEMESTERS': {
    title: 'Evaluación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de comisión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de comisión',
    titleDocument: 'Acta de comisión',
    buttonsProps: {
      textLeft: 'Revocar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REVOKED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'REVIEW-DOCUMENTS-FOR-COMMISSION': {
    title: 'Revisión de documentos',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Observaciones enviadas exitosamente',
    errorMessage: 'Error al enviar las observaciones',
    isEditable: true
  },
  'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER': {
    title: 'Revisión de documentos',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Observaciones enviadas exitosamente',
    errorMessage: 'Error al enviar las observaciones',
    isEditable: true
  },
  'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER': {
    title: 'Autorización de pago',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La autorización de pago ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la autorización de pago',
    question:
      '¿Desea autorizar la confección de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST',
      two: 'PURCHASE-ORDER-REQUEST-EDIT',
      three: 'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER'
    },
    isEditable: true
  },
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': {
    title: 'Orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La ordern de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Documentos Respaldatorios',
    question:
      '¿Deseas añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER': {
    title: 'Generación orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La ordern de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añade orden de compra',
    question:
      '¿Deseas añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': {
    title: 'Orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La orden de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añadir archivo de orden de compra firmada',
    question:
      '¿Deseas añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER'
    },
    isEditable: true
  },
  'PAYMENT-MADE-APPLICANT-OFFICER': {
    title: 'Pago Realizado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir comprobante de pago',
    question:
      '¿Desea añadir el comprobante de pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': {
    title: 'Recepción de certificado o factura',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir certificado',
    question:
      '¿Deseas añadir el certificado de envío de certificado para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': {
    title: 'Oficio de envío de certificado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir oficio firmado',
    question:
      '¿Deseas añadir el oficio de envío de certificado para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER'
    },
    isEditable: true
  },
  'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER': {
    title: 'Oficio recepcionado por finanzas',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La observación ha sido enviada con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la observación',
    titleDocument: '',
    question:
      '¿Deseas avanzar el proceso todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER'
    },
    isEditable: true
  },
  'CORRECT-REQUEST-APPLICANT-OFFICER': {
    title: 'Subsanar solicitud',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir documento',
    question:
      '¿Desea subsanar la solicitud de todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER-DAC': {
    title: 'Revisión de documentos',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Observaciones enviadas exitosamente',
    errorMessage: 'Error al enviar las observaciones',
    isEditable: true
  },
  'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC': {
    title: 'Autorización de pago',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La autorización de pago ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la autorización de pago',
    question:
      '¿Desea autorizar la confección de orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST',
      two: 'PURCHASE-ORDER-REQUEST-EDIT',
      three: 'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
    },
    isEditable: true
  },
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La ordern de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Documentos Respaldatorios',
    question:
      '¿Deseas añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER-DAC': {
    title: 'Generación orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La ordern de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añade orden de compra',
    question:
      '¿Deseas añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Orden de compra',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La orden de compra ha sido enviado, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la orden de compra',
    titleDocument: 'Añadir archivo de orden de compra firmada',
    question:
      '¿Deseas añadir la orden de compra para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
    },
    isEditable: true
  },
  'PAYMENT-MADE-APPLICANT-OFFICER-DAC': {
    title: 'Pago Realizado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir comprobante de pago',
    question:
      '¿Desea añadir el comprobante de pago para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': {
    title: 'Recepción de certificado o factura',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir certificado',
    question:
      '¿Deseas añadir el certificado de envío de certificado para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': {
    title: 'Oficio de envío de certificado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El pago ha sido realizado con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir oficio firmado',
    question:
      '¿Deseas añadir el oficio de envío de certificado para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC'
    },
    isEditable: true
  },
  'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER-DAC': {
    title: 'Oficio recepcionado por finanzas',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La observación ha sido enviada con Éxito, n° de registros actualizados: ',
    errorMessage: 'Error al enviar la observación',
    titleDocument: '',
    question:
      '¿Deseas avanzar el proceso todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    keyToSearch: {
      one: 'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC'
    },
    isEditable: true
  },
  'CORRECT-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Subsanar solicitud',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir documento',
    question:
      '¿Desea subsanar la solicitud de todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING': {
    title: 'Determinación de gerencia',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La determinación de gerencia ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la determinación de gerencia',
    titleDocument: 'Añadir documento (opcional)',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true
  },
  'REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING': {
    title: 'Revisión de información',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La revisión de información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la revisión de información',
    titleDocument: 'Añadir documento (opcional)',
    isEditable: true
  },
  'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING': {
    title: 'Solicitud de pago a monitores',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud de pago ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de pago',
    titleDocument: 'Añadir documento (opcional)',
    isEditable: true
  },
  'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING': {
    title: 'Verificación',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La verificación ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la verificación',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    isEditable: true,
    keyToSearch: {
      one: 'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING'
    }
  },
  'PAYMENT-MADE-WORKSHOPS-AND-TRAINING': {
    title: 'Pago realizado',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El pago ha sido realizado con Éxito',
    errorMessage: 'Error al realizar el pago',
    titleDocument: 'Añadir comprobante de pago',
    keyToSearch: {
      one: 'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING'
    },
    isEditable: true
  },
  'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT': {
    title: 'Añadir oficio para finanzas',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añadir oficio',
    question:
      '¿Desea añadir el oficio de finanzas para todos los kits entregados en el mes?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'REVIEW-DOCUMENTS-SOLIDARITY-HEALTH-FUND': {
    title: 'Revisión de documentos',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Observaciones enviadas exitosamente',
    errorMessage: 'Error al enviar las observaciones',
    isEditable: true
  },
  'NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': {
    title: 'Contactar socio vía telefónica',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Contacto al socio enviado exitosamente',
    errorMessage: 'Error al enviar el contacto al socio',
    isEditable: true
  },
  'CONTINUE-PROCESS-NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': {
    title: 'Continuar con el proceso de solicitud',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Contacto al socio enviado exitosamente',
    errorMessage: 'Error al enviar el contacto al socio',
    isEditable: true
  },
  'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND': {
    title: 'Preparación de comisión',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La comisión ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la comisión',
    titleObservation: 'Breve reseña del caso',
    isEditable: true
  },
  'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud de orden de compra',
    titleDocument: 'Añade datos transferencia / otros',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La solicitud de orden de compra ha sido preparada con Éxito',
    errorMessage: 'Error al preparar la solicitud de orden de compra',
    isEditable: true
    /* keyToSearch: {
      one: 'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND'
    } */
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Verificación de oficio',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La verificación de oficio de orden ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la verificación de oficio de orden',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      two: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Verificación de oficio (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La verificación de oficio de orden ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la verificación de oficio de orden',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      two: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND': {
    title: 'Verificación de oficio',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La verificación de oficio de orden ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la verificación de oficio de orden',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND',
      two: 'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud orden de compra (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud orden de compra ha sido enviado con Éxito',
    errorMessage: 'Error al enviar la solicitud orden de compra',
    keyToSearch: {
      one: 'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud de orden de compra (a corregir)',
    titleDocument: 'Añade datos transferencia / otros',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud de orden de compra ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de orden de compra',
    keyToSearch: {
      // one: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      // two: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND',
      three: 'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND': {
    title: 'Autorización de confección de orden de compra',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La autorización de confección de orden de compra ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la autorización de confección de orden de compra',
    buttonsProps: {
      // textLeft: 'No autorizado',
      // iconLeft: RejectedIcon,
      // colorLeft: 'red',
      // optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED'],
      textRightExtra: 'Corregir',
      iconRightExtra: MinusIcon,
      colorLeftExtra: 'yellow',
      optionRightExtra: ['EDIT']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      two: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND',
      three: 'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      four: 'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND',
      five: 'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'PURCHASE-ORDER-AUTORIZATION-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Autorización de confección de orden de compra (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La autorización de confección de orden de compra ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la autorización de confección de orden de compra',
    buttonsProps: {
      textLeft: 'No autorizado',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['NOT-AUTHORIZED'],
      textRight: 'Autorizado',
      iconRight: CheckIcon,
      optionRight: ['AUTORIZED'],
      textRightExtra: 'Corregir',
      iconRightExtra: MinusIcon,
      colorLeftExtra: 'yellow',
      optionRightExtra: ['EDIT']
    },
    buttonsPropsExtra: {
      textLeft: 'Retroceder',
      iconLeft: ReplayIcon,
      colorLeft: 'yellow',
      optionLeft: ['BACK'],
      textRight: 'Finalizar',
      iconRight: CheckIcon,
      colorRight: 'red',
      optionRight: ['FINISH']
    },
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      two: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND',
      three: 'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      four: 'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND',
      five: 'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'CORRECT-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Subsanación de solicitud',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La subsanación de solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la subsanación de solicitud',
    isEditable: true
  },
  'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': {
    title: 'Orden de compra',
    titleDocument: 'Orden de compra',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La orden de compra ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la orden de compra',
    keyToSearch: {
      one: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      two: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND',
      three: 'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND',
      four: 'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND',
      five: 'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': {
    title: 'Orden de compra firmada',
    titleDocument: 'Orden de compra firmada',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La Información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la Información',
    keyToSearch: {
      one: 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true,
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    }
  },
  'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': {
    title: 'Valor estimado de ayuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El comprobante de pago ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el comprobante de pago',
    isEditable: true
  },
  'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': {
    title: 'Pago realizado',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage: 'El comprobante de pago ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el comprobante de pago',
    titleDocument: 'Añade comprobante de pago',
    question:
      '¿Desea agregar los pagos realizados para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': {
    title: 'Certificado de pago y oficio para finanzas',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'El certificado y oficio para finanzas ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el certificado y oficio para finanzas',
    titleDocument: 'Añade oficio para finanzas',
    question:
      '¿Desea autorizar agregar los pagos realizados para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    isEditable: true
  },
  'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND': {
    title: 'Recepción de factura, certificado u otro comprobante',
    actionKey: 'MULTI-PROGRAM-PROCESSES',
    succesMessage:
      'La recepción de factura, certificado u otro comprobante ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la recepción de factura, certificado u otro comprobante',
    titleDocument: 'Añade certificado y oficio para finanzas',
    question:
      '¿Desea hacer la recepción de factura, certificado u otro comprobante para todos los aprobados de este programa?',
    questionConfirm:
      '¿Estas avanzando el proceso, seguro que deseas continuar?',
    detailsConfirm:
      'Recuerda que todos los documentos que subas y las observaciones que añadas se asignaran a todas las solicitudes previamente seleccionadas.',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND',
      two: 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': {
    title: 'Comprobantes enviados y se ha solicitado factura',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La información ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la información',
    isEditable: true
  },
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío de facturas a finanzas',
    titleDocument: 'Oficio para finanzas',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio',
    isEditable: true
  },
  'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': {
    title: 'Recepción de factura, certificado u otro comprobante',
    titleDocument: 'Oficio para finanzas',
    actionKey: 'OTHER-PROCESSES',
    succesMessage:
      'La recepción de factura, certificado u otro comprobante ha sido enviada con Éxito',
    errorMessage:
      'Error al enviar la recepción de factura, certificado u otro comprobante',
    buttonsProps: {
      textLeft: 'Corregir',
      iconLeft: MinusIcon,
      colorLeft: 'yellow',
      optionLeft: ['EDIT'],
      textRight: 'Aprobado',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND',
      two: 'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND',
      three: 'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío de facturas a finanzas (a corregir)',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio',
    titleDocument: 'Añade el oficio para finanzas',
    isEditable: true
  },
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío y recepción de actas',
    titleDocument: 'Acta de entrega firmada',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El acta de entrega ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el acta de entrega',
    isEditable: true
  },
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SIGN-SOLIDARITY-HEALTH-FUND': {
    title: 'Acta de entrega firmada',
    titleDocument: 'Acta de entrega firmada',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El acta de entrega ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el acta de entrega',
    isEditable: true
  },
  'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND': {
    title: 'Ayuda otorgada',
    subTitle:
      'Actualiza a este paso sólo cuando se hayan añadido todas las facturas y oficios para finanzas correspondientes a esta solicitud.',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El acta de entrega ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el acta de entrega',
    isEditable: true
  },
  'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': {
    title: 'Certificado de deuda',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El certificado de deuda ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el certificado de deuda',
    isEditable: true
  },
  'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud orden de compra',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud orden de compra ha sido enviado con Éxito',
    errorMessage: 'Error al enviar la solicitud orden de compra',
    keyToSearch: {
      one: 'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND': {
    title: 'Documentación para pagos y/o compras',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La documentación ha sido enviado con Éxito',
    errorMessage: 'Error al enviar la documentación',
    isEditable: true
  },
  'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío de certificado y oficio para finanzas',
    titleDocument: 'Oficio para finanzas',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El oficio ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el oficio',
    isEditable: true
  },
  'REVIEW-CONTRIBUTIONS-SOLIDARITY-HEALTH-FUND': {
    title: 'Revisión de aportes',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'Observaciones enviadas exitosamente',
    errorMessage: 'Error al enviar las observaciones',
    keyToSearch: {
      one: 'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'END-PROCESS': {
    actionKey: 'OTHER-PROCESSES',
    question:
      '¿Desea finalizar el proceso para todos los aprobados de este programa?',
    title: 'Finalización del proceso'
  },
  'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION': {
    title: 'Certificado de permanencia',
    actionKey: 'OTHER-PROCESSES',
    titleDocument: 'Añade documento de certificado de permanencia',
    succesMessage: 'El certificado de permanencia ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el certificado de permanencia',
    isEditable: true
  },
  'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': {
    title: 'Registro de extensión o salida',
    titleObservation: 'Observación de extensión o salida',
    succesMessage:
      'El registro de extensión o salida ha sido actualizado con Éxito',
    errorMessage: 'Error al actualizar el registro de extensión o salida',
    isEditable: true
  },
  'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': {
    title: 'Registro de extensión o salida',
    titleObservation: 'Observación de extensión o salida',
    succesMessage:
      'El registro de extensión o salida ha sido actualizado con Éxito',
    errorMessage: 'Error al actualizar el registro de extensión o salida',
    keyToSearch: {
      one: 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION',
      two: 'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION',
      three: 'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION'
    },
    isEditable: true
  },
  'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION': {
    title: 'Determinación de solicitud de extensión',
    titleObservation: 'Resolución',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud de extensión ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud de extensión',
    buttonsProps: {
      textLeft: 'Rechazar solicitud',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['REJECTED'],
      textRight: 'Aprobar solicitud',
      iconRight: CheckIcon,
      optionRight: ['APPROVED']
    },
    keyToSearch: {
      one: 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION',
      two: 'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION'
    },
    isEditable: true
  },
  'RESIGNATION-OR-LOSS-BENEFIT-RESIGNATION': {
    title: 'Renuncia o pérdida de beneficio',
    succesMessage:
      'La renuncia o pérdida de beneficio ha sido Actualizada con Éxito',
    errorMessage: 'Error al actualizar la renuncia o pérdida de beneficio',
    titleDocument: 'Añade documentación de evidencia',
    isEditable: true
  },
  'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION': {
    title: 'Autorización de renuncia o perdida',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La autorización ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la autorización',
    buttonsProps: {
      textLeft: 'No autorizar',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['APPROVED', 'INREVIEW', 'INCOMPLETE'],
      textRight: 'Autorizar',
      iconRight: CheckIcon,
      optionRight: ['REJECTED', 'REVOKED']
    },
    keyToSearch: {
      one: 'RESIGNATION-OR-LOSS-BENEFIT-RESIGNATION'
    },
    isEditable: true
  },
  'CORRECT-REQUEST-RESIGNATION': {
    title: 'Subsanar solicitud',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: 'Añade documentación',
    isEditable: true
  },
  'REGISTER-NOTIFICATION-DEATH-OF-MEMBER': {
    title: 'Registro de notificación',
    succesMessage: 'El Registro de notificación ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el Registro de notificación',
    titleObservation: 'Observación',
    isEditable: true
  },
  'REGISTER-NOTIFICATION-TWO-DEATH-OF-MEMBER': {
    title: 'Registro de notificación',
    succesMessage: 'El Registro de notificación ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el Registro de notificación',
    titleObservation: 'Observación',
    isEditable: true
  },
  'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL': {
    title: '¿Desea continuar con el siguiente semestre?',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'La solicitud ha sido enviada con Éxito',
    errorMessage: 'Error al enviar la solicitud',
    titleDocument: '',
    buttonsProps: {
      textLeft: 'No, Finalizar',
      iconLeft: RejectedIcon,
      colorLeft: 'red',
      optionLeft: ['FINALIZED'],
      textRight: 'Si, continuar',
      iconRight: CheckIcon,
      optionRight: ['ADD-NEW-SEMESTERS']
    },
    isEditable: true
  },
  'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER': {
    title: 'Monto transferido',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: '',
    keyToSearch: {
      one: 'PAYMENT-MADE-APPLICANT-OFFICER'
    },
    isEditable: true
  },
  'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC': {
    title: 'Monto transferido',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: '',
    keyToSearch: {
      one: 'PAYMENT-MADE-APPLICANT-OFFICER-DAC'
    },
    isEditable: true
  },
  'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL': {
    title: 'Monto transferido',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: '',
    keyToSearch: {
      one: 'PAYMENT-MADE-TECHNICAL-PROFESSIONAL'
    },
    isEditable: true
  },
  'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': {
    title: 'Monto transferido',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: '',
    keyToSearch: {
      one: 'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS'
    },
    isEditable: true
  },
  'TRANSFERRED-AMOUNT-PRE-UNIVERSITY': {
    title: 'Monto transferido',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: '',
    keyToSearch: {
      one: 'PAYMENT-MADE-PRE-UNIVERSITY'
    },
    isEditable: true
  },
  'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND': {
    title: 'Monto transferido',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: '',
    keyToSearch: {
      one: 'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND'
    },
    isEditable: true
  },
  'USED-AMOUNT-SOLIDARITY-HEALTH-FUND': {
    title: 'Monto ejecutado',
    actionKey: 'OTHER-PROCESSES',
    succesMessage: 'El monto ha sido enviado con Éxito',
    errorMessage: 'Error al enviar el monto',
    titleDocument: 'Añade comprobante de pago',
    keyToSearch: {
      one: 'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND',
      two: 'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
    },
    validation: [
      {
        key: 'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND',
        fieldToValidate: 'usedAmount',
        errorMessage:
          'El valor estimado de ayuda ingresado previamente es: "{}"'
      }
    ],
    isEditable: true
  },
  'ONLY-DESCRIPTIONS-HOSPITAL-ACCOMMODATION': {
    title: 'Observaciones generales',
    isEditable: false
  }
}

/* ============================ */

export const keyDictionaryTitle = {
  'NOTIFY-REJECTED-STATUS-PHONE-SOLIDARITY-HEALTH-FUND': {
    title: 'Notificación de rechazo por vía telefónica',
    subTitle: null,
    notifiedBy: 'Whatsapp y correo electrónico',
    succesMessage: 'Observación Guardada con Éxito',
    errorMessage: 'Error al guardar la observación',
    titleObservation: 'Observación'
  },
  'NOTIFY-NEW-STATUS-PHONE': {
    title: 'Notificación de nuevo estado por vía telefónica',
    subTitle: null,
    notifiedBy: 'Telefono',
    succesMessage: 'Observación Guardada con Éxito',
    errorMessage: 'Error al guardar la observación',
    titleObservation: 'Observación'
  },
  'NOTIFY-NEW-STATUS': {
    title: 'Notificación al solicitante',
    subTitle: 'Puedes verificar el mensaje y editarlo antes de enviarlo',
    notifiedBy: 'Whatsapp y correo electrónico',
    succesMessage: 'Notificación enviada con Exíto',
    errorMessage: 'Error al enviar la notificación',
    titleObservation: 'Mensaje a enviar'
  },
  'NOTIFY-REGISTRATION': {
    title: 'Notificación al solicitante',
    subTitle: 'Puedes verificar el mensaje y editarlo antes de enviarlo',
    notifiedBy: 'Whatsapp y correo electrónico',
    succesMessage: 'Notificación enviada con Exíto',
    errorMessage: 'Error al enviar la notificación',
    titleObservation: 'Mensaje a enviar'
  },
  'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': {
    title: 'Solicitud nominación de giftcard',
    titleDocument: 'Nominación de giftcard'
  },
  'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': {
    title: 'Solicitud de desbloqueo y nominación giftcard',
    titleDocument: 'Nominación de giftcard'
  },
  'GIFTCARD-DELIVERY-NEWBORN-BENEFIT': {
    title: 'Entrega de giftcard nominadas solicitadas',
    titleDocument: 'Documento Adjunto'
  },
  'END-PROCESS': {
    title: 'Finalización del proceso',
    titleDocument: 'Declaración jurada simple'
  },
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT': {
    title: 'Evaluación del depto. de control social y gestión social'
  },
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT': {
    title: 'Evaluación del depto. de control social y gestión social'
  },
  'STOCK-RECIEVED-NEWBORN-BENEFIT': {
    title: 'Evaluación del depto. de finanzas'
  },
  'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER': {
    title: 'Evaluación del depto. de control social y gestión social'
  },
  'GIFTCARD-RECEPTION-NEWBORN-BENEFIT': {
    title: 'Envío y recepción de giftcards',
    titleDocument: 'Documento Adjunto'
  },
  'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT': {
    title: 'Activación de giftcards y proceso de inventario',
    titleDocument: 'Otros documentos'
  },
  'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 1',
    titleDocument: 'Oficio de solicitud de pago Nº 1'
  },
  'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': {
    title: 'Verificación de solicitud de pago Nº 1',
    titleDocument: 'Oficio firmado'
  },
  'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': {
    title: 'Autorización de solicitud de pago Nº 1',
    titleDocument: 'Oficio firmado'
  },
  'PAYMENT-ONE-MADE-DEATH-OF-MEMBER': {
    title: 'Documentación de pago Nº 1',
    titleDocument: 'Comprobante de transferencia'
  },
  'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 2',
    titleDocument: 'Oficio de solicitud de pago Nº 2'
  },
  'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': {
    title: 'Verificación de solicitud de pago Nº 2',
    titleDocument: 'Oficio firmado'
  },
  'PAYMENT-TWO-MADE-DEATH-OF-MEMBER': {
    title: 'Documentación de pago Nº 2',
    titleDocument: 'Comprobante de transferencia'
  },
  'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 1 (Reenviado)',
    titleDocument: 'Oficio de solicitud de pago Nº 1'
  },
  'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER': {
    title: 'Oficio de solicitud de pago Nº 2 (Reenviado)',
    titleDocument: 'Oficio de solicitud de pago Nº 2'
  },
  'COMMISSION-PREPARATION': {
    title: 'Preparación de comisión',
    titleDocument: 'Documentos Adjuntos',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-APPLICANT-OFFICER': {
    title: 'Preparación de comisión',
    titleDocument: 'Documentos Adjuntos',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-APPLICANT-OFFICER-DAC': {
    title: 'Preparación de comisión',
    titleDocument: 'Documentos Adjuntos',
    titleObservation: 'Reseña social del caso'
  },
  'COMMISSION-PREPARATION-PRE-UNIVERSITY': {
    title: 'Preparación de comisión',
    titleDocument: 'Documentos Adjuntos',
    titleObservation: 'Reseña social del caso'
  },
  'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND': {
    title: 'Determinación de comisión',
    titleDocument: 'Acta de comisión',
    titleObservation: 'Aporte'
  },
  'EVALUATION-COMMISSION-UNIVERSITY': {
    title: 'Evaluación de comisión',
    titleDocument: 'Acta de comisión'
  },
  'EVALUATION-COMMISSION-APPLICANT-OFFICER': {
    title: 'Evaluación de comisión',
    titleDocument: 'Acta de comisión'
  },
  'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC': {
    title: 'Evaluación de comisión',
    titleDocument: 'Acta de comisión'
  },
  'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL': {
    title: 'Evaluación de comisión',
    titleDocument: 'Acta de comisión'
  },
  'EVALUATION-COMMISSION-NEW-SEMESTERS': {
    title: 'Evaluación de comisión',
    titleDocument: 'Acta de comisión'
  },
  'EVALUATION-COMMISSION-PRE-UNIVERSITY': {
    title: 'Evaluación de comisión',
    titleDocument: 'Acta de comisión'
  },
  'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY': {
    title: 'Informe Preuniversitario y solicitar monto a cancelar',
    titleDocument: 'Dpcumentos'
  },
  'PURCHASE-ORDER-REQUEST': {
    title: 'Oficio de solicitud de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': {
    title: 'Oficio de solicitud de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'PURCHASE-ORDER-REQUEST-EDIT': {
    title: 'Oficio de solicitud de orden de compra (Corregido)',
    titleDocument: 'Documentos Adjuntos'
  },
  'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY': {
    title: 'Oficio de solicitud de orden de compra (Corregido)',
    titleDocument: 'Documentos Adjuntos'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST': {
    title: 'Verificación de solicitud de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': {
    title: 'Verificación de solicitud de orden de compra',
    titleDocument: 'Oficio firmado'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Verificación de solicitud de orden de compra',
    titleDocument: 'Oficio firmado'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL': {
    title: 'Verificación de solicitud de orden de compra',
    titleDocument: 'Oficio firmado'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS': {
    title: 'Verificación de solicitud de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': {
    title: 'Verificación de solicitud de orden de compra',
    titleDocument: 'Oficio firmado'
  },
  'PURCHASE-ORDER-AUTORIZATION': {
    title: 'Autorización de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY': {
    title: 'Autorización de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'PURCHASE-ORDER-PRE-UNIVERSITY': {
    title: 'Orden de compra',
    titleDocument: 'Documento de orden de compra'
  },
  'PAYMENT-MADE-PRE-UNIVERSITY': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de transferencia'
  },
  'PAYMENT-MADE-APPLICANT-OFFICER': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de transferencia'
  },
  'PAYMENT-MADE-APPLICANT-OFFICER-DAC': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de transferencia'
  },
  'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY': {
    title: 'Informar pago',
    titleDocument: 'Documentos'
  },
  'ATTACH-INVOICE-BY-PRE-UNIVERSITY': {
    title: 'Factura enviada por el preuniversitario',
    titleDocument: 'Factura recibida'
  },
  'OFFICE-FOR-FINANCES-PRE-UNIVERSITY': {
    title: 'Oficio para finanzas',
    titleDocument: 'Oficio'
  },
  'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY': {
    title: 'Verificación de oficio para finanzas',
    titleDocument: 'Documentos'
  },
  'OFFICE-FOR-FINANCES-PRE-UNIVERSITY-EDIT': {
    title: 'Oficio para finanzas (Reenviado)',
    titleDocument: 'Oficio'
  },
  'ADD-PROPOSAL-DISASTER-SUPPORT': {
    title: 'Oficio de propuesta',
    titleDocument: 'Oficio de propuesta'
  },
  'ADD-PROPOSAL-FIRE-SUPPORT': {
    title: 'Oficio de propuesta',
    titleDocument: 'Oficio de propuesta'
  },
  'LEGAL-REPORT-DISASTER-SUPPORT': {
    title: 'Informe jurídico',
    titleDocument: 'Informe jurídico'
  },
  'LEGAL-REPORT-FIRE-SUPPORT': {
    title: 'Informe jurídico',
    titleDocument: 'Informe jurídico'
  },
  'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT': {
    title: 'Determinación de gerencia'
  },
  'MANAGEMENT-DETERMINATION-FIRE-SUPPORT': {
    title: 'Determinación de gerencia'
  },
  'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT': {
    title: 'Determinación de presidencia'
  },
  'PRESIDENSY-DETERMINATION-FIRE-SUPPORT': {
    title: 'Determinación de presidencia'
  },
  'INFORM-SERVICE-SOCIAL-DETERMINATION': {
    title: 'Informar al servicio social',
    titleDocument: 'Documentos Adjunto'
  },
  'OFFICE-WITH-RESOLUTION': {
    title: 'Oficio con resolución',
    titleDocument: 'Oficio con resolución'
  },
  'AID-APPROVAL-DISASTER-SUPPORT': {
    title: 'Oficio de aprobación de ayuda (sin verificar)',
    titleDocument: 'Oficio de aprobación de ayuda'
  },
  'AID-APPROVAL-FIRE-SUPPORT': {
    title: 'Oficio de aprobación de ayuda (sin verificar)',
    titleDocument: 'Oficio de aprobación de ayuda'
  },
  'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT': {
    title: 'Verificación de oficio de aprobación de ayuda'
  },
  'VALIDATE-AID-APPROVAL-FIRE-SUPPORT': {
    title: 'Verificación de oficio de aprobación de ayuda'
  },
  'AID-APPROVAL-EDIT-DISASTER-SUPPORT': {
    title: 'Oficio de aprobación de ayuda (Reenviado) (sin verificar)',
    titleDocument: 'Oficio de aprobación de ayuda'
  },
  'AID-APPROVAL-EDIT-FIRE-SUPPORT': {
    title: 'Oficio de aprobación de ayuda (Reenviado) (sin verificar)',
    titleDocument: 'Oficio de aprobación de ayuda'
  },
  'AID-DEPOSIT-DISASTER-SUPPORT': {
    title: 'Depósito de ayuda'
  },
  'AID-DEPOSIT-FIRE-SUPPORT': {
    title: 'Depósito de ayuda'
  },
  'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT': {
    title: 'Notificar vía correo electrónico al Servicio Social',
    titleObservation: 'Observaciones',
    succesMessage: 'Observación Guardada con Éxito',
    errorMessage: 'Error al guardar la observación'
  },
  'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT': {
    title: 'Notificar vía correo electrónico al Servicio Social',
    titleObservation: 'Observaciones',
    succesMessage: 'Observación Guardada con Éxito',
    errorMessage: 'Error al guardar la observación'
  },
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT': {
    title: 'Notificación de acta firmada por el socio y el beneficiario',
    titleDocument: 'Acta firmada por el socio y el beneficiario'
  },
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL':
    {
      title: 'Notificación de acta firmada por el socio y el beneficiario',
      titleDocument: 'Acta firmada por el socio y el beneficiario'
    },
  'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY': {
    title: 'Notificación de acta firmada por el socio y el beneficiario',
    titleDocument: 'Acta firmada por el socio y el beneficiario'
  },
  'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY': {
    title: 'Se informó a la universidad sobre el becado'
  },
  'PURCHASE-ORDER-REQUEST-UNIVERSITY': {
    title: 'Solicitud de orden de compra'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY': {
    title: 'Verificación de solicitud'
  },
  'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY': {
    title: 'Solicitud de orden de compra (Reenviado)'
  },
  'PURCHASE-ORDER-AUTORIZATION-UNIVERSITY': {
    title: 'Autorización de confección de orden de compra',
    titleDocument: 'Documentos Adjuntos'
  },
  'GENERATE-PURCHASE-ORDER-UNIVERSITY': {
    title: 'Orden de compra para ser firmada',
    titleDocument: 'Documento de orden de compra'
  },
  'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY': {
    title: 'Orden de compra para ser firmada (Reenviado)',
    titleDocument: 'Documento de orden de compra'
  },
  'SIGN-PURCHASE-ORDER-UNIVERSITY': {
    title: 'Orden de compra firmada',
    titleDocument: 'Documento de orden de compra firmada'
  },
  'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY': {
    title: 'Orden de compra firmada',
    titleDocument: 'Orden de compra firmada'
  },
  'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY': {
    title: 'Orden de compra',
    titleDocument: 'Orden de compra'
  },
  'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT': {
    title: 'Orden de compra (Reenviado)',
    titleDocument: 'Orden de compra'
  },
  'CORRECT-REQUEST': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'CORRECT-REQUEST-PRE-UNIVERSITY': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'CORRECT-REQUEST-UNIVERSITY': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'PAYMENT-MADE-UNIVERSITY': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de pago'
  },
  'SOCIAL-ANALYSIS-UNIVERSITY': {
    title: 'Análisis social',
    titleDocument: 'Documentos Adjuntos'
  },
  'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY': {
    title: 'Se informó a la universidad sobre el pago de aranceles'
  },
  'DELIVERY-OFFICE-UNIVERSITY': {
    title: 'Oficio de entrega',
    titleDocument: 'Oficio de entrega'
  },
  'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY': {
    title: 'Recepción de Factura'
  },
  'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY': {
    title: 'Oficio de entrega (Reenviado)',
    titleDocument: 'Oficio de entrega'
  },
  'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL': {
    title: 'Monto a cancelar',
    titleDocument: 'Documentos Adjuntos'
  },
  'AMOUNT-TO-CANCEL-NEW-SEMESTERS': {
    title: 'Monto a cancelar',
    titleDocument: 'Documentos Adjuntos'
  },
  'REVIEW-OF-SUBMITTED-INFORMATION': {
    title: 'Revisión de información enviada por el preuniversitario'
  },
  'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL': {
    title: 'Oficio y factura',
    titleDocument: 'Oficio y factura'
  },
  'OFFICE-AND-INVOICE-NEW-SEMESTERS': {
    title: 'Oficio y factura',
    titleDocument: 'Oficio y factura'
  },
  'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL': {
    title: 'Oficio y factura (Reenviado)',
    titleDocument: 'Oficio y factura'
  },
  'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT': {
    title: 'Oficio y factura (Reenviado)',
    titleDocument: 'Oficio y factura'
  },
  'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL': {
    title: 'Autorización de pago'
  },
  'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': {
    title: 'Autorización de pago'
  },
  'PAYMENT-MADE-TECHNICAL-PROFESSIONAL': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de pago'
  },
  'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de pago'
  },
  'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL': {
    title: 'Se informó al instituto sobre el pago de aranceles'
  },
  'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS': {
    title: 'Se informó al instituto sobre el pago de aranceles'
  },
  'REVIEW-DOCUMENTS-FOR-COMMISSION': {
    title: 'Revisión de documentos'
  },
  'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER': {
    title: 'Revisión de documentos'
  },
  'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER': {
    title: 'Autorización de pago'
  },
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': {
    title: 'Orden de compra',
    titleDocument: 'Documentos Respaldatorios'
  },
  'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': {
    title: 'Orden de compra',
    titleDocument: 'Orden de compra firmada'
  },
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': {
    title: 'Recepción de certificado o factura',
    titleDocument: 'Certificado'
  },
  'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': {
    title: 'Oficio de envío de certificado',
    titleDocument: 'Oficio firmado'
  },
  'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER': {
    title: 'Oficio recepcionado por finanzas'
  },
  'CORRECT-REQUEST-APPLICANT-OFFICER': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER-DAC': {
    title: 'Revisión de documentos'
  },
  'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC': {
    title: 'Autorización de pago'
  },
  'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Orden de compra',
    titleDocument: 'Documentos Respaldatorios'
  },
  'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Orden de compra',
    titleDocument: 'Orden de compra firmada'
  },
  'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': {
    title: 'Recepción de certificado o factura',
    titleDocument: 'Certificado'
  },
  'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': {
    title: 'Oficio de envío de certificado',
    titleDocument: 'Oficio firmado'
  },
  'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER-DAC': {
    title: 'Oficio recepcionado por finanzas'
  },
  'CORRECT-REQUEST-APPLICANT-OFFICER-DAC': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING': {
    title: 'Determinación de gerencia',
    titleDocument: 'Documentos Adjuntos'
  },
  'REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING': {
    title: 'Revisión de información'
  },
  'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING': {
    title: 'Solicitud de pago a monitores',
    titleDocument: 'Documentos Adjuntos'
  },
  'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING': {
    title: 'Verificación'
  },
  'PAYMENT-MADE-WORKSHOPS-AND-TRAINING': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de pago'
  },
  'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT': {
    title: 'Oficio',
    titleDocument: 'Documentos Adjuntos'
  },
  'REVIEW-DOCUMENTS-SOLIDARITY-HEALTH-FUND': {
    title: 'Revisión de documentos'
  },
  'NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': {
    title: 'Contactar socio vía telefónica'
  },
  'CONTINUE-PROCESS-NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': {
    title: 'Continuar con el proceso de solicitud'
  },
  'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND': {
    title: 'Preparación de comisión'
  },
  'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud de orden de compra',
    titleDocument: 'Datos transferencia / otros'
  },
  'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND': {
    title: 'Recepción de factura, certificado u otro comprobante'
  },
  'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud de orden de compra (Reenviado)',
    titleDocument: 'Datos transferencia / otros'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Verificación de oficio'
  },
  'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND': {
    title: 'Verificación de oficio'
  },
  'PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND': {
    title: 'Autorización de confección de orden de compra'
  },
  'CORRECT-REQUEST-SOLIDARITY-HEALTH-FUND': {
    title: 'Subsanar',
    titleDocument: 'Documentos Adjuntos'
  },
  'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': {
    title: 'Orden de compra',
    titleDocument: 'Orden de compra'
  },
  'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': {
    title: 'Orden de compra firmada',
    titleDocument: 'Orden de compra firmada'
  },
  'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': {
    title: 'Valor estimado de ayuda',
    titleDocument: 'Comprobante de pago'
  },
  'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': {
    title: 'Pago Realizado',
    titleDocument: 'Comprobante de pago'
  },
  'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': {
    title: 'Comprobantes enviados y se ha solicitado factura'
  },
  'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': {
    title: 'Certificado de pago y oficio para finanzas',
    titleDocument: 'Oficio para finanzas'
  },
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío de facturas a finanzas',
    titleDocument: 'Oficio para finanzas'
  },
  'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': {
    title: 'Recepción de factura, certificado u otro comprobante',
    titleDocument: 'Oficio para finanzas'
  },
  'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío de facturas a finanzas (Reenviado)',
    titleDocument: 'Oficio de entrega de factura'
  },
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío y recepción de actas',
    titleDocument: 'Acta de entrega firmada'
  },
  'DELIVERY-AND-RECEIPT-CERTIFICATE-SIGN-SOLIDARITY-HEALTH-FUND': {
    title: 'Acta de entrega firmada',
    titleDocument: 'Acta de entrega firmada'
  },
  'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND': {
    title: 'Ayuda otorgada'
  },
  'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': {
    title: 'Certificado de deuda'
  },
  'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud orden de compra'
  },
  'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND': {
    title: 'Solicitud orden de compra (a corregir)'
  },
  'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND': {
    title: 'Documentación para pagos y/o compras'
  },
  'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND': {
    title: 'Envío de certificado y oficio para finanzas',
    titleDocument: 'Orden de compra firmada'
  },
  'REVIEW-CONTRIBUTIONS-SOLIDARITY-HEALTH-FUND': {
    title: 'Revisión de aportes'
  },
  'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL': {
    title: 'Preparación de comisión'
  },
  'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': {
    title: 'Verificación de Oficio'
  },
  'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION': {
    title: 'Certificado de permanencia',
    titleDocument: 'Documento de certificado de permanencia'
  },
  'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': {
    title: 'Registro de extensión o salida',
    titleDocument: 'Documentos Adjuntos'
  },
  'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': {
    title: 'Registro de extensión o salida',
    titleDocument: 'Documentos Adjuntos'
  },
  'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION': {
    title: 'Determinación de extensión'
  },
  'RESIGNATION-OR-LOSS-BENEFIT-RESIGNATION': {
    title: 'Renuncia o pérdida de beneficio'
  },
  'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION': {
    title: 'Autorización de renuncia o perdida'
  },
  'CORRECT-REQUEST-RESIGNATION': {
    title: 'Subsanar solicitud'
  },
  'REGISTER-NOTIFICATION-DEATH-OF-MEMBER': {
    title: 'Registro de notificación',
    notifiedBy: 'No Aplica',
    titleObservation: 'Observaciones'
  },
  'REGISTER-NOTIFICATION-TWO-DEATH-OF-MEMBER': {
    title: 'Registro de notificación',
    notifiedBy: 'No Aplica',
    titleObservation: 'Observaciones'
  },
  'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL': {
    title: 'validación de semestres'
  },
  'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER': {
    title: 'Monto transferido'
  },
  'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC': {
    title: 'Monto transferido'
  },
  'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL': {
    title: 'Monto transferido'
  },
  'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': {
    title: 'Monto transferido'
  },
  'TRANSFERRED-AMOUNT-PRE-UNIVERSITY': {
    title: 'Monto transferido'
  },
  'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND': {
    title: 'Monto transferido'
  },
  'USED-AMOUNT-SOLIDARITY-HEALTH-FUND': {
    title: 'Monto ejecutado'
  },
  'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER': {
    title: 'Generación orden de compra',
    titleDocument: 'Orden de compra'
  },
  'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER-DAC': {
    title: 'Generación orden de compra',
    titleDocument: 'Orden de compra'
  },
  'ONLY-DESCRIPTIONS-HOSPITAL-ACCOMMODATION': {
    title: 'Observaciones generales'
  }
}

export function useTypeModal(key) {
  return {
    isEditable: true,
    ...keyDictionary[key]
  }
}

export function useTypeModalView(key, programState) {
  const titleByState = {
    APPROVED: 'Notificación aprobación al solicitante',
    REJECTED: 'Notificación rechazo al solicitante',
    INREVIEW: 'Notificación de en revisión al solicitante'
  }

  if (hasOwnProperty.call(keyDictionaryTitle, key)) {
    if (key === 'NOTIFY-NEW-STATUS') {
      return {
        ...keyDictionaryTitle[key],
        title: titleByState[programState] || keyDictionaryTitle[key]?.tittle
      }
    }

    return keyDictionaryTitle[key]
  }
  return {
    title: '?',
    titleDocument: '?'
  }
}
