import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const steepOneSchema = Yup.object().shape({
  receptionDate: Yup.string().required('La fecha de recepción es requerida'),
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida'),
  civilStatus: Yup.string().required('El estado civil es requerido'),
  region: Yup.string().required('La región es requerida'),
  commune: Yup.string().required('La comuna es requerida'),
  address: Yup.string().required('La dirección es requerida'),
  relationship: Yup.string().required('El parentesco es requerido'),
  alternativePhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido'),
  residenceCondition: Yup.string().required(
    'Las condiciones de residencia son requeridas'
  ),
  observations: Yup.string(),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El telefono es requerido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerZone: Yup.string(),
  partnerPrefecture: Yup.string(),
  partnerEndowment: Yup.string()
})

export const steepTwoSchema = Yup.object().shape({
  postulantName: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('El nombre completo es requerido'),
  phonePostulant: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El teléfono es requerido'),
  addressPostulant: Yup.string().required('El domicilio es requerido'),
  emailPostulant: Yup.string()
    .email()
    .required('El correo electrónico es requerido'),
  unitPostulant: Yup.string().required('La unidad es requerida'),
  fuasRequest: Yup.string().required('La Postulación F.U.A.S. es requerida'),
  score: Yup.string().required('La nota es requerida'),
  antiquity: Yup.string(),
  commandersName: Yup.string().max(
    40,
    'El nombre no debe exceder los 40 caracteres'
  ),
  grade: Yup.string().required('El grado es requerido'),
  academicOpinion: Yup.string(),
  monthlyFeeStatement: Yup.string().required(
    'El estado del arancel es requerido'
  )
})

export const steepThreeSchema = Yup.object().shape({
  memberName: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('El nombre completo es requerido'),
  memberAge: Yup.string().required('La edad es requerida'),
  memberRelationship: Yup.string().required('El parentesco es requerido'),
  // scholarship: Yup.string().required('La escolaridad es requerida'),
  memberActivity: Yup.string().required('La actividad es requerida'),
  memberIncomes: Yup.string().required('Los ingresos son requeridos'),
  // memberForecast: Yup.string().required('La previsión es requerida'),
  /* --- health data --- */
  haveMedicalHistory: Yup.boolean(),
  doctorName: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  hospitalName: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  monthlyExpenditure: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string().required('El gasto mensual es Requerido')
  }),
  diagnostic: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  evolutionTime: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  observation: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  medicalHistoryName: Yup.array()
})
export const steepThreeExtraSchema = Yup.object().shape({
  descriptionsFamilyMembers: Yup.string()
})

export const steepFourSchema = Yup.object().shape({
  salatyPartner: Yup.string(),
  // .required('El salario es requerido'),
  isSalatySpouse: Yup.boolean(),
  salarySpouse: Yup.string().when('isSalatySpouse', {
    is: true,
    then: Yup.string()
    // .required('El salario es requerido')
  }),
  otherSalaty: Yup.string(),
  // .required('El salario es requerido'),
  totalExpensesGroup: Yup.string(),
  // .required(
  //   'El Total de egresos mensuales es Requerido'
  // ),
  salarySettlementsNames: Yup.array().when('isSalatySpouse', {
    is: true,
    then: Yup.array()
    // .min(1, 'Se requieren al Menos 1 documentos')
    // .required('Las Liquidaciones de sueldo son Requeridas')
  })
})

export const steepFiveSchema = Yup.object().shape({
  memberNameHealth: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('El nombre completo es requerido'),
  monthlyExpenditureHealth: Yup.string().required(
    'El gasto mensual es requerido'
  ),
  diagnosticHealth: Yup.string().required('El diagnóstico es requerido'),
  familyRelationshipHealth: Yup.string().required('El parentesco es requerido'),
  medicalHistoryNameHealth: Yup.array()
    .min(1, 'Se requieren al Menos 1 documentos')
    .required('Los antecedentes médicos son Requeridos'),
  observationHealth: Yup.string().required('La observación es requerida')
})

export const steepSixSchema = Yup.object().shape({
  registerSocialName: Yup.array(),
  // .min(1, 'Se requieren al Menos 1 documentos')
  // .required('La ficha social es requerida'),
  additionalDocumentsNames: Yup.array(),
  observationsRequest: Yup.string()
})
