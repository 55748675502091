import React, { useState } from 'react'
import { Grid, GridItem, Tr, Td, Flex } from '@chakra-ui/react'
import { Table, Spinner, Alert } from '../UI'
import { FormatDate, FormatMoney } from '../../Utils'
import { useDiscounts } from '../../hooks/Discounts'

const TableDiscountsPartners = ({ filtersDiscounts, limit, tableSize }) => {
  const [page, setPage] = useState(0)
  const {
    data: discountsList,
    isLoading,
    isFetching,
    isError
  } = useDiscounts(filtersDiscounts, page, limit)
  const headers = [
    '#',
    'Fecha de Creación',
    'Fecha de Descuento',
    'Monto Descontado'
  ]
  return (
    <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
      <Flex w={'100%'} justify={'flex-end'} py={3}>
        <Spinner size={'lg'} show={isLoading || isFetching} />
      </Flex>
      {isError && (
        <Flex w={'100%'} justify={'flex-end'} py={3}>
          <Alert status={'error'} message={'Error al Cargar los Datos'} />
        </Flex>
      )}
      <GridItem
        overflowX={'auto'}
        display={{
          lg: 'table',
          md: 'break-word',
          sm: 'break-word'
        }}
        css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#2E7423',
            borderRadius: '20px'
          }
        }}
      >
        <Table
          headers={headers}
          totalRecords={discountsList?.totalRecords}
          setPage={setPage}
          page={page}
          size={tableSize}
          limit={limit}
        >
          {discountsList?.currentPages?.map((item, index) => (
            <Tr key={item._id}>
              <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                {index + 1 + page * limit}
              </Td>
              <Td color={'grey.500'}>{FormatDate(`${item?.createdAt}`)}</Td>
              <Td color={'grey.500'}>
                {item?.month} {item?.year}
              </Td>
              <Td color={'grey.500'}>$ {FormatMoney(item?.amount)}</Td>
            </Tr>
          ))}
        </Table>
      </GridItem>
    </Grid>
  )
}

export default TableDiscountsPartners
