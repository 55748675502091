/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from 'react-query'

import {
  createNewProgram,
  updateProgram,
  addRegisteredToProgram,
  downloadRegistered
} from '../../api/programs/workshopsAndTraining'

const key = 'workshopsAndTraining'
const keyAll = 'Allprograms'

const getAction = (type) => {
  if (type === 'CREATE') return createNewProgram
  if (type === 'UPDATE') return updateProgram
  if (type === 'REGISTER-TO-PROGRAM') return addRegisteredToProgram
  if (type === 'DOWNLOAD-REGISTERED') return downloadRegistered
  return null
}

export function useMutateProgram(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([key])
      if (type === 'REGISTER-TO-PROGRAM') {
        queryClient.invalidateQueries([keyAll])
      }
      return response
    }
  })
}
